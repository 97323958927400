import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';
import { commaEvery3rdChar } from '../../../../../../utils';

export default function PubCoMethod({ reportData, totalPageCount, exhibitNumber }) {
  const gridData = reportData.PublicCompanyMethod.grids;
  const tableData = gridData.multiples['Company Name'];

  const { enterpriseValue } = reportData.PublicCompanyMethod;
  const columnOrder = [
    'Company Name',
    'Ticker',
    'Revenue Multiple | LTM',
    'Revenue Multiple | LFY',
    'Revenue Multiple | CFY',
  ];

  const columnOrder2 = [
    'Company Name',
    'Revenue Multiples | LTM',
    'Revenue Multiples | LFY',
    'Revenue Multiples | CFY',
  ];
  const table3Headers = Object.keys(gridData.weights).filter((header) => columnOrder2.includes(header));
  const table3Data = gridData.weights.headers;
  const table1Headers = Object.keys(gridData.multiples).filter((header) => columnOrder.includes(header));

  function returnCellData(cellData, dataName, rowIndex, columnIndex, table) {
    let grid;
    if (table === 'firstTable') grid = gridData.multiples;
    if (table === 'summaryFirstTable') grid = gridData.multiplesSummary;
    if (table === 'thirdTable') grid = gridData.selection;
    if (table === 'fourthTable') {
      grid = gridData.weights;
      return columnOrder2.includes(dataName) ? grid[dataName][rowIndex] : '';
    }
    const cellValue = grid[dataName][rowIndex];
    if (dataName.includes('Multiple') && table !== 'fourthTable') {
      return cellValue ? `${commaEvery3rdChar(parseFloat(cellValue).toFixed(2))}x` : 'N/A';
    }
    return cellValue || 'N/A';
  }
  const valuationDate = moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const fiscalYear = moment(valuationDate).subtract(1, 'years').format('MM/DD/YYYY');
  const financialStatementDate = moment(reportData.companyInfo.financialStatementDate).format('MM/DD/YYYY');

  const mapper = {
    'Company Name': 'Company name',
    Ticker: 'Ticker',
    'Revenue Multiple | LFY': `Last fiscal year (LFY)\n${fiscalYear}`,
    'Revenue Multiple | LTM': `Last 12 months (LTM)\n${financialStatementDate}`,
    'Revenue Multiple | CFY': `Current fiscal year (CFY)\n${valuationDate}`,
  };

  function sortColumnNames(names, order) {
    const sortedNames = names.filter((name) => order.includes(name));
    sortedNames.sort((a, b) => order.indexOf(a) - order.indexOf(b));
    return sortedNames;
  }

  const sortedHeaders = sortColumnNames(table1Headers, columnOrder);


  function columnWidth(index) {
    if (index === 0) return '224px';
    if (index === 1) return '114px';
    return '180px';
  }

  // function columnWidth2(index) {
  //   if (index === 0) return '334px';
  //   return '180px';
  // }

  const numberOfPages = (Math.ceil((tableData.length + 10) / 37)) || 1;

  function customMap(array, callback, startIndex = 0) {
    return array.map((element, index) => callback(element, index + startIndex));
  }

  return (
    <>
      {[...Array(numberOfPages)].map((_, i) => i).map((pageNumber, pageIndex) => (
        <Page
          key={pageNumber}
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Guideline Public Company Method   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '84px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '24px',
                backgroundColor: '#0C2617',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#8AB9A4',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  marginRight: '8px',
                  width: '360px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                (USD$)
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '792px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                Net EV/Revenue
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '40px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {sortedHeaders.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: columnWidth(index),
                    marginTop: 'auto',
                    paddingLeft: '16px',
                    paddingRight: '8px',
                    letterSpacing: '0.4px',
                    lineHeight: '0.55mm',
                    textAlign: index > 1 && 'right',
                  }}
                >
                  {mapper[dataName]}
                </Text>
              ))}
            </View>
            {customMap(tableData.slice(pageIndex * 37, (pageIndex + 1) * 37), (cellData, rowIndex) => (
              <View
                key={cellData}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, columnIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: [1, 3].includes(rowIndex) ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(columnIndex),
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: columnIndex > 1 && 'right',
                    }}
                  >
                    {returnCellData(cellData, dataName, rowIndex, columnIndex, 'firstTable') || 'N/A'}
                  </Text>
                ))}
              </View>
            ), pageIndex * 37)}
          </View>
          {tableData.slice(pageIndex * 37, (pageIndex + 1) * 37).length < 18 && (
            <>
              <View
                style={{
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '1160px',
                  border: '1px',
                  borderColor: '#AFB1B3',
                }}
              >
                {[
                  ['Maximum', 'max'], ['95th percentile', '95%'],
                  ['90th percentile', '90%'], ['75th percentile', '75%'],
                  ['Median', 'median'], ['25th percentile', '25%'],
                  ['10th percentile', '10%'], ['5th percentile', '5%'],
                  ['Minimum', 'min'], ['Average', 'mean'],
                  ['Selected multiple', 'selectedMultiple'],
                ].map((rowName, rowIndex) => (
                  <View
                    key={rowName}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '16px',
                      backgroundColor: `${rowIndex > 9 ? '#9ECEBB' : rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                      ...rowIndex === 10 && {
                        borderTop: '1px',
                        borderColor: '#CCCCCC',
                      },
                      ...rowIndex === 11 && {
                        borderTop: '1px',
                        borderColor: '#EAE9EC',
                      },
                    }}
                  >
                    {table1Headers.map((dataName, cellIndex) => (
                      <Text
                        key={dataName}
                        style={{
                          fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          width: columnWidth(cellIndex),
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex !== 0 && 'right',
                        }}
                      >
                        {cellIndex === 0 ? rowName[0] :
                          cellIndex === 1 ? '' :
                            returnCellData(rowName, dataName, rowIndex, cellIndex, 'summaryFirstTable') || 'N/A'}
                      </Text>
                    ))}
                  </View>
                ))}
              </View>

              {/* <View
                style={{
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '1160px',
                  border: '1px',
                  borderColor: '#AFB1B3',
                }}
              >
                {reportData.PublicCompanyMethod.grids.weights.headers.map((rowName, rowIndex) => (
                  <View
                    key={rowName}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '16px',
                      backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                      ...rowIndex === 1 && {
                        borderTop: '1px',
                        borderBottom: '1px',
                        borderColor: '#CCCCCC',
                      },
                    }}
                  >
                    {Object.keys(reportData.PublicCompanyMethod.grids.weights).map((dataName, cellIndex) => (
                      <Text
                        key={dataName}
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          width: columnWidth2(cellIndex),
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex !== 0 && 'right',
                        }}
                      >
                        {cellIndex === 0 ? rowName :
                          returnCellData(rowName, dataName, rowIndex, cellIndex, 'fourthTable') || 'N/A'}
                      </Text>
                    ))}
                  </View>
                ))}
              </View> */}

              <View
                style={{
                  width: '1160px',
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '24px',
                  backgroundColor: '#9ECEBB',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '14px',
                    color: '#49454F',
                    paddingRight: '16px',
                    letterSpacing: '0.08px',
                  }}
                >
                  Enterprise value&nbsp;
                  <Text
                    style={{
                      fontFamily: 'RobotoBold',
                      fontSize: '16px',
                      color: '#000000',
                      letterSpacing: '0.1px',
                    }}
                  >
                    {enterpriseValue}
                  </Text>
                </Text>
              </View>
              <BottomPagination
                pageNumber={totalPageCount + pageIndex}
                reportData={reportData}
              />
            </>
          )}
          <BottomPagination
            pageNumber={totalPageCount + pageIndex}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      ))}
      {tableData.slice((numberOfPages - 1) * 37, numberOfPages * 37).length > 18 && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader companyName={reportData.companyInfo.companyName} pageName="Guideline Public Company Method   |   Exhibit 12" />
          <View
            style={{
              marginTop: '16px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            {[
              ['Maximum', 'max'], ['95th percentile', '95%'],
              ['90th percentile', '90%'], ['75th percentile', '75%'],
              ['Median', 'median'], ['25th percentile', '25%'],
              ['10th percentile', '10%'], ['5th percentile', '5%'],
              ['Minimum', 'min'], ['Average', 'mean'],
              ['Selected multiple', 'selectedMultiple'], ['Relative selection', 'relativeSelection'],
            ].map((rowName, rowIndex) => (
              <View
                key={rowName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex > 9 ? '#9ECEBB' : rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  ...rowIndex === 10 && {
                    borderTop: '1px',
                    borderColor: '#CCCCCC',
                  },
                  ...rowIndex === 11 && {
                    borderTop: '1px',
                    borderColor: '#EAE9EC',
                  },
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      width: columnWidth(cellIndex),
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {cellIndex === 0 ? rowName[0] :
                      cellIndex === 1 ? '' :
                        returnCellData(rowName, dataName, rowIndex, cellIndex, 'summaryFirstTable') || 'N/A'}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <View
            style={{
              marginTop: '16px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
            }}
          >
            {table3Data.map((rowName, rowIndex) => (
              <View
                key={rowName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  ...rowIndex === 1 && {
                    borderTop: '1px',
                    borderBottom: '1px',
                  },
                }}
              >
                {table3Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: rowIndex === 1 ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: columnWidth(cellIndex),
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {cellIndex === 0 ? rowName : cellIndex === 1 ? '' : 'HELLO'}
                  </Text>
                ))}
              </View>
            ))}
          </View>

          <View
            style={{
              width: '1160px',
              marginTop: '16px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#9ECEBB',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '14px',
                color: '#49454F',
                paddingRight: '16px',
                letterSpacing: '0.08px',
              }}
            >
              Enterprise value&nbsp;
              <Text
                style={{
                  fontFamily: 'RobotoBold',
                  fontSize: '16px',
                  color: '#000000',
                  letterSpacing: '0.1px',
                }}
              >
                {enterpriseValue}
              </Text>
            </Text>
          </View>
          <BottomPagination
            pageNumber={totalPageCount + numberOfPages}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}

    </>
  );
}

PubCoMethod.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
