/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// TODO Handle accessibility for mouse over events
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import getCompsColumns from './compsTableColumns';

import { copy } from '../../../../utils';

import './CompTableHeader.scss';

function SortingChevron({
  sortedColumn,
  columnSorting,
  setColumnSorting,
  columnHeaderHovered,
}) {
  return (
    <IconButton
      className={`chevron-icon${columnSorting.column === sortedColumn ? ' active' : ''}` +
        `${columnHeaderHovered !== sortedColumn ? ' hidden' : ''}`}
      onClick={() => {
        if (columnSorting.column !== sortedColumn || columnSorting.order === 'ascending') {
          setColumnSorting({ column: sortedColumn, order: 'descending' });
        } else {
          setColumnSorting({ column: sortedColumn, order: 'ascending' });
        }
      }}
    >
      {columnSorting.column !== sortedColumn || columnSorting.order === 'descending' ?
        <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
    </IconButton>
  );
}

SortingChevron.propTypes = {
  columnSorting: PropTypes.object.isRequired,
  setColumnSorting: PropTypes.func.isRequired,
  columnHeaderHovered: PropTypes.string.isRequired,
  sortedColumn: PropTypes.string.isRequired,
};

export default function CompTableHeader({
  tabSelected,
  compData,
  setCompData,
  filters,
  headerScroll,
  reSummarize,
}) {
  const [columnHeaderHovered, setColumnHeaderHovered] = useState('');

  const [columnSorting, setColumnSorting] = useState({});

  useEffect(() => {
    const extractCompVal = (val) => Array.isArray(val) ? val[0] : val;
    if (columnSorting.column) {
      const sortByDate = ['Last FYE Date', 'IPO Date'].includes(columnSorting.column);
      const sortAlphabetically = ['Name', 'Identifier', 'Ticker', 'Industry'].includes(columnSorting.column);
      const sortedCompData = copy(compData);
      sortedCompData.shift();
      sortedCompData.sort((a, b) => {
        const sortOrder = columnSorting.order === 'descending' ? [a, b] : [b, a];
        if (!a[columnSorting.column]) return 1;
        if (!b[columnSorting.column]) return -1;
        if (sortByDate) {
          return moment(sortOrder[0][columnSorting.column], 'MM/DD/YYYY') -
            moment(sortOrder[1][columnSorting.column], 'MM/DD/YYYY');
        }
        if (sortAlphabetically) {
          return sortOrder[0][columnSorting.column].localeCompare(sortOrder[1][columnSorting.column]);
        }
        return extractCompVal(sortOrder[0][columnSorting.column]) - extractCompVal(sortOrder[1][columnSorting.column]);
      });
      sortedCompData.unshift(compData[0]);
      setCompData(sortedCompData);
    }
  }, [columnSorting]);

  return (
    <div className="CompTableHeader">
      <div className="fixed-left">
        <div className="table-header-top-titles">
          <div className="comp-list"><h6>Company list</h6></div>
        </div>
        <div className="table-sub-headers">
          <div className="sub-header-group comp-list">
            <Tooltip
              PopperProps={{ className: 'bottom-arrow-tooltip select-deselect top-start' }}
              title="Selects and deselects all"
              placement="top-start"
              enterDelay={500}
              enterNextDelay={1500}
              arrow
            >
              <Checkbox
                checked={tabSelected === 'comps' ? compData.some((data) => data.Active) &&
                  compData.some((data) => data.Multiples) : compData.some((data) => data.Active)}
                onChange={() => reSummarize('all', tabSelected === 'industry' ? 'active' : 'multiple')}
                icon={<div className="unchecked-icon" />}
                checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
              />
            </Tooltip>
            <div
              className="sub-header"
              onMouseOver={() => { if (columnHeaderHovered !== 'Name') setColumnHeaderHovered('Name'); }}
              onMouseLeave={() => setColumnHeaderHovered('')}
            >
              <div className="sub-header-title">
                <h6>Full Company name</h6>
                <span>&nbsp;</span>
              </div>
              <SortingChevron
                sortedColumn="Name"
                columnSorting={columnSorting}
                setColumnSorting={setColumnSorting}
                columnHeaderHovered={columnHeaderHovered}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-scrollable" ref={headerScroll}>
        <div className="table-header-top-titles">
          {getCompsColumns(compData, tabSelected, filters)
            .map((columnGroup) => (
              <div key={columnGroup.columnGroupName} style={{ minWidth: `${columnGroup.columnGroupWidth}px` }}>
                <h6>{columnGroup.columnGroupName}</h6>
              </div>
            ))}
        </div>
        <div className="table-sub-headers">
          {getCompsColumns(compData, tabSelected, filters)
            .map((columnGroup) => (
              <div key={columnGroup.columnGroupName} className="sub-header-group">
                {columnGroup.columnGroupChildren.map((column) => (
                  <div
                    key={column.field}
                    className={`sub-header ${!column.notSortable ? 'sortable' : ''}`}
                    style={{ minWidth: `${column.width}px` }}
                    onMouseOver={() => { if (columnHeaderHovered !== column.field) setColumnHeaderHovered(column.field); }}
                    onMouseLeave={() => setColumnHeaderHovered('')}
                  >
                    <div className="sub-header-title">
                      <h6>{column.headerName}</h6>
                      <span>{column.headerSubName}</span>
                    </div>
                    {!column.notSortable && (
                      <SortingChevron
                        sortedColumn={column.field}
                        columnSorting={columnSorting}
                        setColumnSorting={setColumnSorting}
                        columnHeaderHovered={columnHeaderHovered}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

CompTableHeader.propTypes = {
  tabSelected: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  compData: PropTypes.array.isRequired,
  setCompData: PropTypes.func.isRequired,
  headerScroll: PropTypes.object.isRequired,
  reSummarize: PropTypes.func.isRequired,
};
