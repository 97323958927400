import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Document, Font } from '@react-pdf/renderer';

import CoverPage from './pages/CoverPage';
import TableOfContents from './pages/TableOfContents';
import CompanyDescription from './pages/CompanyDescription';
import Exhibits from './pages/Exhibits';

import robotoFont from './assets/fonts/Roboto-Regular.ttf';
import robotoMedFont from './assets/fonts/Roboto-Medium.ttf';
import robotoBoldFont from './assets/fonts/Roboto-Black.ttf';
import robotoItalicFont from './assets/fonts/Roboto-Italic.ttf';
import manropeFont from './assets/fonts/Manrope-Regular.ttf';
import manropeLightFont from './assets/fonts/Manrope-Light.ttf';
import manropeMedFont from './assets/fonts/Manrope-Medium.ttf';
import manropeSemiFont from './assets/fonts/Manrope-SemiBold.ttf';
import manropeBoldFont from './assets/fonts/Manrope-Bold.ttf';

export default function PDFRenderer({ reportData }) {
  Font.register({ family: 'Roboto', src: robotoFont });
  Font.register({ family: 'RobotoMed', src: robotoMedFont });
  Font.register({ family: 'RobotoBold', src: robotoBoldFont });
  Font.register({ family: 'RobotoItalic', src: robotoItalicFont });
  Font.register({ family: 'Manrope', src: manropeFont });
  Font.register({ family: 'ManropeLight', src: manropeLightFont });
  Font.register({ family: 'ManropeMed', src: manropeMedFont });
  Font.register({ family: 'ManropeSemi', src: manropeSemiFont });
  Font.register({ family: 'ManropeBold', src: manropeBoldFont });

  Font.registerHyphenationCallback((word) => [word]);

  const hasBacksolve = !!reportData.footnoteData?.valuationMethodologySummary?.backsolve;
  const hasPubCo = !!reportData.OptionPricingAllocation.pubco;
  const hasValueCompare = !!Object.keys(reportData.ValueComparison).length;

  const sortedPubCoDescriptions = (() => {
    const splitLength = [0];
    let compIndex = 0;
    while (compIndex < reportData.PublicCompanyDescriptions['Company Description'].length) {
      let strLength = 0;
      while (strLength < 4650 && compIndex < reportData.PublicCompanyDescriptions['Company Description'].length) {
        strLength += reportData.PublicCompanyDescriptions['Company Description'][compIndex].length;
        compIndex += 1;
      }
      splitLength.push(compIndex);
    }
    return splitLength;
  })();

  let sortedBreakpoints = Object.keys(reportData.BreakpointAnalyses.breakpoints).filter((el) => el.includes(' | '))
    .sort((a, b) => parseFloat(a.split(' | ')[1].split('$')[1]) - parseFloat(b.split(' | ')[1].split('$')[1]));

  let sortedOPMBreakpoints = Object.keys(reportData.OptionPricingAllocation.backsolve?.incrementalOptionValue || []).sort((a, b) => {
    if (a === 'All Participate' || b === 'All Participate') return -1;
    return parseFloat(a.split(' | ')[1].split('$')[2]) - parseFloat(b.split(' | ')[1].split('$')[2]);
  });

  let sortedBacksolveBreakpoints = Object.keys(reportData.OptionPricingAllocation.goalSeek?.incrementalOptionValue || []).sort((a, b) => {
    if (a === 'All Participate' || b === 'All Participate') return -1;
    return parseFloat(a.split(' | ')[1].split('$')[2]) - parseFloat(b.split(' | ')[1].split('$')[2]);
  });

  let sortedPubCoBreakpoints = Object.keys(reportData.OptionPricingAllocation.pubco?.incrementalOptionValue || []).sort((a, b) => {
    if (a === 'All Participate' || b === 'All Participate') return -1;
    return parseFloat(a.split(' | ')[1].split('$')[2]) - parseFloat(b.split(' | ')[1].split('$')[2]);
  });

  function footnotePageCount(startCount, footnoteData, maxLines = 9, charCount = 230) {
    return startCount + (footnoteData ? Math.ceil(footnoteData.length / charCount) : 0) > maxLines ? 1 : 0;
  }

  const splitBreakpoints = (breakpoints) => {
    if (breakpoints.length > 13) {
      const splitIndex = Math.ceil(breakpoints.length / 2);
      return [breakpoints.slice(0, splitIndex), breakpoints.slice(splitIndex)];
    }
    return [[...breakpoints]];
  };

  sortedBreakpoints = splitBreakpoints(sortedBreakpoints);
  sortedOPMBreakpoints = splitBreakpoints(sortedOPMBreakpoints);
  sortedBacksolveBreakpoints = splitBreakpoints(sortedBacksolveBreakpoints);
  sortedPubCoBreakpoints = splitBreakpoints(sortedPubCoBreakpoints);

  const ownershipClasses = reportData.CapitalizationTable['Ownership Class'].length + 2;

  const capTablePagesTotal = 7 + footnotePageCount(ownershipClasses, reportData.footnoteData.capTable);

  const breakpointPagesTotal = capTablePagesTotal + 1 + footnotePageCount(ownershipClasses, reportData.footnoteData.bpAnalysis, 7) *
    (sortedBreakpoints.length > 13 ? 2 : 1);

  const opmBacksolvePagesTotal = breakpointPagesTotal + 1 +
    footnotePageCount(ownershipClasses, reportData.footnoteData.opmAllocation, 10) *
    (sortedOPMBreakpoints.length > 13 ? 2 : 1);

  const opmPubCoPagesTotal = reportData.OptionPricingAllocation.pubco ?
    (opmBacksolvePagesTotal + 1 + footnotePageCount(ownershipClasses, reportData.footnoteData.gpcAllocation, 10) *
      (sortedOPMBreakpoints.length > 13 ? 2 : 1)
    ) : opmBacksolvePagesTotal;

  const fairValuePagesTotal = reportData.FairValuePerShare ?
    (opmPubCoPagesTotal + 1 + footnotePageCount(ownershipClasses, reportData.footnoteData.fairValuePerShare, 10) *
      (sortedOPMBreakpoints.length > 13 ? 2 : 1)
    ) : opmPubCoPagesTotal + 1;

  const compNames = reportData.ValuationDateVolatility.volatilityObject.Name;
  const rowPageCount = (summary = 10) => Math.floor((compNames.length + summary) / 37) || 1;
  const compNameOverflow = (rows = 37, height = 24) => compNames.slice((rowPageCount() - 1) * rows, rowPageCount() * rows).length > height;

  const valVolatilityPageTotal = fairValuePagesTotal + 1;

  const tranVolatilityPageTotal = valVolatilityPageTotal + rowPageCount(compNames) + (compNameOverflow() ? 1 : 0) + 1;

  const marketAdjustPageTotal = tranVolatilityPageTotal + rowPageCount(compNames) + (compNameOverflow() ? 1 : 0) + 1;

  const pubCoDescriptionsPageTotal = marketAdjustPageTotal + rowPageCount(compNames) + (compNameOverflow() ? 1 : 0) + 1;

  const pubCoMethodPageTotal = pubCoDescriptionsPageTotal +
    (reportData.OptionPricingAllocation.pubco ? (sortedPubCoDescriptions.length - 1) : 0);

  const pubCoGrowthMarginsPageTotal = pubCoMethodPageTotal + rowPageCount(compNames) + (compNameOverflow() ? 1 : 0) + 1;

  const generalAppraisersNotes = hasPubCo ? pubCoGrowthMarginsPageTotal : marketAdjustPageTotal + rowPageCount(compNames) + 2;

  const valSumWeighting = generalAppraisersNotes + 1;

  const valSumBacksolve = valSumWeighting + 1;

  const allocationMethodSummary = valSumBacksolve + 1;

  const valueComparisonPageTotal = allocationMethodSummary + 1;

  const totalPageCount = {
    capTable: capTablePagesTotal,
    breakpoints: breakpointPagesTotal,
    opmBacksolve: opmBacksolvePagesTotal,
    opmPubCo: opmPubCoPagesTotal,
    fairValue: fairValuePagesTotal,
    valVolatility: valVolatilityPageTotal,
    tranVolatility: tranVolatilityPageTotal,
    marketAdjustment: marketAdjustPageTotal,
    pubCoDescriptions: pubCoDescriptionsPageTotal,
    pubCoMethod: pubCoMethodPageTotal,
    pubCoGrowthMargins: pubCoGrowthMarginsPageTotal,
    generalAppraisersNotes,
    valSumWeighting,
    valSumBacksolve,
    allocationMethodSummary,
    valueComparison: valueComparisonPageTotal,
  };

  const exhibitNumber = {
    fundHolding: 1,
    equityValue: 2,
    capTable: 3,
    breakpoints: 4,
    opmBacksolve: 5,
    trnDateBacksolveMethod: 6,
    gpcOPMAllocation: 7,
    fairValue: hasPubCo ? 8 : 7,
    valVolatility: hasPubCo ? 9 : 8,
    tranVolatility: hasPubCo ? 10 : 9,
    marketAdjustment: hasPubCo ? 11 : 10,
    pubCoDescriptions: 12,
    pubCoMethod: 13,
    pubCoGrowthMargins: 14,
    generalAppraisersNotes: hasPubCo ? 15 : 11,
    valSumWeighting: hasPubCo ? 16 : 12,
    valSumBacksolve: hasPubCo ? 17 : 13,
    allocationMethodSummary: hasPubCo ? 18 : 14,
    valueComparison: hasPubCo ? 19 : 15,
  };

  const valuationDate = moment(reportData.companyInfo.valuationDate, 'YYYY-MM-DD').format('MMMM DD, YYYY');

  return (
    <Document>
      <CoverPage
        companyName={reportData.companyInfo.companyName}
        valuationDate={valuationDate}
        isDraft={reportData.isDraft}
        isVenBioUser={reportData.isVenBioUser}
      />
      <TableOfContents
        valuationDate={valuationDate}
        totalPageCount={totalPageCount}
        reportData={reportData}
        hasPubCo={hasPubCo}
        hasValueCompare={hasValueCompare}
      />
      <CompanyDescription
        reportData={reportData}
        valuationDate={valuationDate}
      />
      <Exhibits
        reportData={reportData}
        sortedBreakpoints={sortedBreakpoints}
        sortedOPMBreakpoints={sortedOPMBreakpoints}
        sortedBacksolveBreakpoints={sortedBacksolveBreakpoints}
        sortedPubCoBreakpoints={sortedPubCoBreakpoints}
        sortedPubCoDescriptions={sortedPubCoDescriptions}
        totalPageCount={totalPageCount}
        hasValueCompare={hasValueCompare}
        hasBacksolve={hasBacksolve}
        hasPubCo={hasPubCo}
        exhibitNumber={exhibitNumber}
      />
    </Document>
  );
}


PDFRenderer.propTypes = { reportData: PropTypes.object.isRequired };
