import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import CompTableHeader from './CompTableHeader';
import CompTableRow from './CompTableRow';

import './CompTable.scss';

export default function CompTable({
  tabSelected,
  compData,
  setCompData,
  filters,
  setShowAddCompModal,
  headerScroll,
  subCompScroll,
  overlayScrollbarRef,
  rightCompXScrollRef,
  rightCompYScroll,
  rightCompYScrollRef,
  reSummarize,
  summariesOutdated,
  runningComps,
  descriptionsToShow,
  setDescriptionsToShow,
  descriptionsRef,
  checkboxesRef,
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(-1);

  return (
    <div className="CompTable">
      <CompTableHeader
        compData={compData}
        setCompData={setCompData}
        tabSelected={tabSelected}
        filters={filters}
        headerScroll={headerScroll}
        reSummarize={reSummarize}
      />
      {summariesOutdated && (
        <div className="summaries-outdated">
          <WarningAmberRoundedIcon />
          <p>
            Summary statistics are out of date. Keep editing your Company list, when you’re done, Re-run Summary statistics.
          </p>
        </div>
      )}
      <CompTableRow
        compData={compData}
        rowIndex={0}
        tabSelected={tabSelected}
        filters={filters}
        subCompScroll={subCompScroll}
        summariesOutdated={summariesOutdated}
      />
      <div className="comp-table-wrapper" ref={rightCompYScrollRef}>
        <div className="comp-table-rows-wrapper" ref={rightCompXScrollRef}>
          {compData.length && compData.map((comp, index) => index !== 0 && (
            <CompTableRow
              key={comp.Identifier}
              compData={compData}
              rowIndex={index}
              tabSelected={tabSelected}
              setShowConfirmationModal={setShowConfirmationModal}
              filters={filters}
              reSummarize={reSummarize}
              descriptionsToShow={descriptionsToShow}
              setDescriptionsToShow={setDescriptionsToShow}
              descriptionsRef={descriptionsRef}
              checkboxesRef={checkboxesRef}
              summariesOutdated={summariesOutdated}
            />
          ))}
        </div>
      </div>
      {rightCompYScroll?.current && rightCompYScroll.current.scrollHeight - rightCompYScroll.current.clientHeight > 0 && (
        <CompTableRow
          compData={compData}
          rowIndex={0}
          tabSelected={tabSelected}
          filters={filters}
          overlayScrollbarRef={overlayScrollbarRef}
          overlayScroll
        />
      )}
      <div className="table-footer">
        <Button disabled={runningComps} onClick={() => setShowAddCompModal(true)}>
          <AddIcon />
          Add Companies
        </Button>
      </div>
      <Dialog open={showConfirmationModal >= 0 || showConfirmationModal === 'all'} className="active-comp-confirmation-modal">
        <div className="dialog-header">
          <WarningAmberRoundedIcon />
          <span>Are you sure?</span>
        </div>
        {showConfirmationModal === 'all' ? (
          <>
            <p>Some companies were originally removed from the Volatility tab.</p>
            <p>If you add them back here, you&apos;ll also automatically add them back to the Volatility tab&apos;s Company list.</p>
          </>
        ) : (
          <>
            <p>This company was originally removed from the Volatility tab.</p>
            <p>If you add it back here, you&apos;ll also automatically add it back to the Volatility tab&apos;s Company list.</p>
          </>
        )}
        <div className="bottom-btns">
          <Button onClick={() => setShowConfirmationModal(-1)}>Cancel</Button>
          <Button
            onClick={() => {
              setShowConfirmationModal(-1);
              reSummarize(showConfirmationModal, 'add-both');
            }}
          >
            Add to both tab
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

CompTable.propTypes = {
  tabSelected: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setShowAddCompModal: PropTypes.func.isRequired,
  compData: PropTypes.array.isRequired,
  setCompData: PropTypes.func.isRequired,
  headerScroll: PropTypes.object.isRequired,
  subCompScroll: PropTypes.object.isRequired,
  rightCompXScrollRef: PropTypes.func.isRequired,
  rightCompYScroll: PropTypes.object.isRequired,
  rightCompYScrollRef: PropTypes.func.isRequired,
  overlayScrollbarRef: PropTypes.func.isRequired,
  reSummarize: PropTypes.func.isRequired,
  summariesOutdated: PropTypes.bool.isRequired,
  runningComps: PropTypes.bool.isRequired,
  descriptionsToShow: PropTypes.array.isRequired,
  setDescriptionsToShow: PropTypes.func.isRequired,
  descriptionsRef: PropTypes.object.isRequired,
  checkboxesRef: PropTypes.object.isRequired,
};
