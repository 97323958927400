import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import { Checkbox, TextField, Avatar, IconButton } from '@mui/material';

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { toTitleCase } from '../../utils';
import { darkInitials } from '../../utils/globals';

import { UserContext, SubNavStateContext } from '../../contexts';

import InfoTile from './components/InfoTile';
import Notes from './components/Notes';
import SortBy from '../../components/SortBy';
import UploadTile from './components/UploadTile';
import EditButton from './components/EditButton';

import useFetch from '../../hooks/useFetch';

import './GeneralInfo.scss';

export default function GeneralInfo({ enterpriseUsers }) {
  const { userData, setUserData } = useContext(UserContext);
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);

  const [companyInfoDropdownHeight, setCompanyInfoDropdownHeight] = useState('auto');
  const [infoDropdownHeight, setInfoDropdownHeight] = useState('auto');

  const [editingCompanyInfo, setEditingCompanyInfo] = useState(false);
  const [companyInfoIsSaving, setCompanyInfoIsSaving] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  const [editingCompanyDesc, setEditingCompanyDesc] = useState(false);
  const [companyDescSaving, setCompanyDescSaving] = useState(false);

  const [editingUploads, setEditingUploads] = useState(false);
  const [uploadsSaving, setUploadsSaving] = useState(false);

  const [reviewer, setReviewer] = useState();
  const [preparer, setPreparer] = useState();
  const [dataEntry, setDataEntry] = useState();
  const [assigneesData, setAssigneesData] = useState([]);

  const [notesList, setNotesList] = useState([]);

  const [capTableUploads, setCapTableUploads] = useState([]);
  const [articlesUploads, setArticlesUploads] = useState([]);
  const [optionLedgerUploads, setOptionLedgerUploads] = useState([]);
  const [secondaryTransactionUploads, setSecondaryTransactionUploads] = useState([]);
  const [termSheetsUploads, setTermSheetsUploads] = useState([]);
  const [safeOrConvertibleNoteUploads, setSafeOrConvertibleNoteUploads] = useState([]);
  const [financialForecastUploads, setFinancialForecastUploads] = useState([]);
  const [additionalFinancialUploads, setAdditionalFinancialUploads] = useState([]);
  const [customCompanyDescription, setCustomCompanyDescription] = useState(false);

  const [, updateAssigneeRequest] = useFetch();
  const [{ response: companyUpdateResponse, callEnded: companyUpdateFinalized }, companyUpdateRequest] = useFetch();
  const [{ response: transactionUpdateResponse, callEnded: transactionUpdateFinalized }, transactionUpdateRequest] = useFetch();

  const [{ loading: noteListLoading }] = useFetch({
    url: '/notes/transaction-note/get-transaction-notes/',
    urlIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
    onSuccess: setNotesList,
  });

  function parseUserData() {
    if (userData && enterpriseUsers) {
      const parsedOutAssignees = userData.metaData.assignees;
      const preparerAssignee = parsedOutAssignees.preparer.accountId !== 'not assigned' ?
        enterpriseUsers.filter((user) => user.accountId === parsedOutAssignees.preparer.accountId)[0] :
        'not assigned';
      const reviewerAssignee = parsedOutAssignees.reviewer.accountId !== 'not assigned' ?
        enterpriseUsers.filter((user) => user.accountId === parsedOutAssignees.reviewer.accountId)[0] :
        'not assigned';
      const dataEntryAssignee = parsedOutAssignees.dataEntry.accountId !== 'not assigned' ?
        enterpriseUsers.filter((user) => user.accountId === parsedOutAssignees.dataEntry.accountId)[0] :
        'not assigned';
      setAssigneesData([dataEntryAssignee, preparerAssignee, reviewerAssignee]);
      if (dataEntryAssignee !== 'not assigned') setDataEntry(`${dataEntryAssignee?.firstName} ${dataEntryAssignee?.lastName}`);
      if (reviewerAssignee !== 'not assigned') setReviewer(`${reviewerAssignee?.firstName} ${reviewerAssignee?.lastName}`);
      if (preparerAssignee !== 'not assigned') setPreparer(`${preparerAssignee?.firstName} ${preparerAssignee?.lastName}`);
    }
  }

  useEffect(() => {
    if (userData.metaData && enterpriseUsers) parseUserData();
  }, [userData.metaData, enterpriseUsers]);

  useEffect(() => {
    if (userData.transactionData) {
      setCompanyInfo({
        portfolioCompanyName: userData.companyData?.companyName,
        companyIndustry: userData.transactionData.basicInfo?.companyIndustry,
        whiteGlove: userData.metaData?.whiteGlove,
        userHasAccess: userData.transactionData?.userHasAccess,
        capIqIdentifier: userData.companyData?.capIqIdentifier,
        description: userData.companyData?.description,
        customDescription: userData.companyData?.customDescription,
        rollForward: userData.transactionData?.rollForward,
        fiscalYearEnd: userData.transactionData.basicInfo?.fiscalYearEnd,
      });
      if (!userData.companyData.capIqIdentifier && !userData.companyData.customDescription) {
        setCustomCompanyDescription(false);
      } else {
        setCustomCompanyDescription(true);
      }
      // parse uploads
      setCapTableUploads(userData.transactionData?.capTableUploads || []);
      setArticlesUploads(userData.transactionData?.articlesUploads || []);
      setOptionLedgerUploads(userData.transactionData?.optionLedgerUploads || []);
      setSecondaryTransactionUploads(userData.transactionData?.secondaryTransactionUploads || []);
      setTermSheetsUploads(userData.transactionData?.termSheetsUploads || []);
      setSafeOrConvertibleNoteUploads(userData.transactionData?.safeOrConvertibleNoteUploads || []);
      setFinancialForecastUploads(userData.transactionData?.financialForecastUploads || []);
      setAdditionalFinancialUploads(userData.transactionData?.additionalFinancialUploads || []);
    }
  }, [userData]);

  function getInitials(name) {
    if (!name) return name;
    const splitName = name.split(' ');
    const firstInitial = Array.from(splitName[0])[0];
    const lastInitial = splitName[1] ? Array.from(splitName[1])[0] : '';
    return firstInitial + lastInitial;
  }

  function assignUserInDB(user, type) {
    let roleType = type.toLowerCase();
    roleType = roleType.split(' ').reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
    const assigneeData = {
      assigneeUserId: user.accountId,
      role: roleType,
    };
    setSubNavState({
      ...subNavState, assigneeData: {
        assigneeUserId: user.accountId,
        role: roleType,
        transactionId: userData.metaData.transactionId,
      },
    });
    updateAssigneeRequest({
      url: '/transactions/asc820/update-role-assignee',
      method: 'post',
      body: assigneeData,
      bodyIds: ['enterpriseCompanyId', 'investorCompanyId', 'portfolioCompanyId', 'transactionId', 'projectId', 'requestUserId'],
    });
  }

  function updateGenCompanyData() {
    const updatedGenData = {
      ...userData.transactionData,
      basicInfo: {
        ...userData.transactionData.basicInfo,
        companyIndustry: companyInfo?.companyIndustry,
        fiscalYearEnd: companyInfo?.fiscalYearEnd,
      },
      capTableUploads,
      articlesUploads,
      optionLedgerUploads,
      secondaryTransactionUploads,
      termSheetsUploads,
      safeOrConvertibleNoteUploads,
      financialForecastUploads,
      additionalFinancialUploads,
      whiteGlove: companyInfo?.whiteGlove,
      userHasAccess: companyInfo?.userHasAccess,
      rollForward: companyInfo?.rollForward,
    };
    const companyData = {
      ...userData.companyData,
      ...companyInfo,
      companyName: companyInfo.portfolioCompanyName,
    };
    companyUpdateRequest({
      url: '/companies/portfolio/update-company-data',
      method: 'post',
      body: companyData,
    });
    transactionUpdateRequest({
      url: '/transactions/asc820/enterprise-update-transaction',
      method: 'post',
      body: updatedGenData,
    });
  }

  useEffect(() => {
    if (companyUpdateResponse && transactionUpdateResponse) {
      setCompanyInfo({
        portfolioCompanyName: companyUpdateResponse.companyName,
        companyIndustry: userData.transactionData.basicInfo?.companyIndustry,
        whiteGlove: userData.metaData?.whiteGlove,
        userHasAccess: transactionUpdateResponse?.userHasAccess,
        capIqIdentifier: companyUpdateResponse.capIqIdentifier,
        description: companyUpdateResponse.description,
        customDescription: companyUpdateResponse.customDescription,
      });
      setUserData({ ...userData, companyData: { ...companyUpdateResponse }, transactionData: { ...transactionUpdateResponse } });
      if (companyUpdateResponse.description) {
        setCustomCompanyDescription(true);
      }
    }
  }, [companyUpdateResponse, transactionUpdateResponse]);

  useEffect(() => {
    if (companyUpdateFinalized && transactionUpdateFinalized) {
      setEditingUploads(false);
      setEditingCompanyInfo(false);
      setUploadsSaving(false);
      setCompanyInfoIsSaving(false);
      setEditingCompanyDesc(false);
      setCompanyDescSaving(false);
    }
  }, [companyUpdateFinalized, transactionUpdateFinalized]);

  function updateAssigneeData(value, type) {
    let newAssignmentData;
    setAssigneesData((prevAssigneesData) => {
      const newData = [...prevAssigneesData];
      if (type === 'Data entry') {
        setDataEntry(value[0]);
        newData[0] = enterpriseUsers[value[1]];
        newAssignmentData = enterpriseUsers[value[1]];
      } else if (type === 'Preparer') {
        setPreparer(value[0]);
        newData[1] = enterpriseUsers[value[1]];
        newAssignmentData = enterpriseUsers[value[1]];
      } else if (type === 'Reviewer') {
        setReviewer(value[0]);
        newData[2] = enterpriseUsers[value[1]];
        newAssignmentData = enterpriseUsers[value[1]];
      }
      return newData;
    });
    assignUserInDB(newAssignmentData, type);
  }

  return (
    <main className="GeneralInfo">
      <div className="info-dropdown">
        <div
          role="button"
          className="header-row"
          onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
          onKeyDown={(e) => { if (e.key === 'Enter') setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
          tabIndex={0}
        >
          <IconButton
            className="chevron-btn"
            onClick={() => { setCompanyInfoDropdownHeight(companyInfoDropdownHeight ? 0 : 'auto'); }}
          >
            <ExpandCircleDownOutlinedIcon className={`circle-chevron${companyInfoDropdownHeight ? ' upward' : ' downward'}`} />
          </IconButton>
          <h5>General company information</h5>
          <EditButton
            isEditing={editingCompanyInfo}
            setIsEditing={setEditingCompanyInfo}
            infoSaving={companyInfoIsSaving}
            setInfoSaving={setCompanyInfoIsSaving}
            updateFunction={() => updateGenCompanyData()}
          />
        </div>
        <div>
          <AnimateHeight duration={500} height={companyInfoDropdownHeight}>
            <div className="info-block">
              <div className="data-tiles first-row">
                <InfoTile
                  label="Company name"
                  dataName="portfolioCompanyName"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                />
                <InfoTile
                  label="Cap IQ Identifier (optional)"
                  dataName="capIqIdentifier"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                />
                <InfoTile
                  label="Company industry"
                  dataName="companyIndustry"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                />
              </div>
              <div className="data-tiles">
                <InfoTile
                  label="Roll forward"
                  dataName="rollForward"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isRadio
                />
                <InfoTile
                  label="White glove service"
                  dataName="whiteGlove"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isRadio
                />
                <InfoTile
                  label="Client has access to company&apos;s financial information?"
                  dataName="userHasAccess"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isRadio
                />
              </div>
              <div className="data-tiles">
                <InfoTile
                  label="Fiscal year end"
                  dataName="fiscalYearEnd"
                  isEditing={editingCompanyInfo}
                  values={companyInfo}
                  setValues={setCompanyInfo}
                  isDate
                />
              </div>
            </div>
            <div className="info-block">
              <p className="info-block-label">
                Assignments -
                <span> Assign or reassign people to work on this company.</span>
              </p>
              <div className="assignees-avatars-row">
                {['Data entry', 'Preparer', 'Reviewer'].map((type, idx) => (
                  <div className="assignees-avatars-block" key={type}>
                    <SortBy
                      sortByList={enterpriseUsers.map((user) => `${user.firstName} ${user.lastName}`)}
                      sortBy=""
                      setSortBy={(value) => updateAssigneeData(value, type)}
                      type={type}
                      isButton
                      className="info-assigned-to"
                      tooltip
                      tooltipCopy={type === 'Data entry' ?
                        'Inputs and check accuracy of data' :
                        type === 'Preparer' ?
                          'Makes selections to come to initial values' :
                          'Reviews and determines if assumptions, selections, and/or conclusions are reasonable'
                      }
                    />
                    <Avatar
                      className="assignees-avatars"
                      style={{
                        backgroundColor: assigneesData[idx] ? assigneesData[idx]?.avatarColor : '#DDD9DE',
                        color: !assigneesData[idx]?.avatarColor ? 'black' :
                          darkInitials.includes(assigneesData[idx]?.avatarColor) ? '#49454F' : '#DDD9DE',
                      }}
                    >
                      {type === 'Data entry' && dataEntry ?
                        getInitials(toTitleCase(dataEntry)) :
                        type === 'Preparer' && preparer ?
                          getInitials(toTitleCase(preparer)) :
                          type === 'Reviewer' && reviewer ?
                            getInitials(toTitleCase(reviewer)) :
                            <PersonOutlineOutlinedIcon />}
                    </Avatar>
                  </div>
                ))}
              </div>
            </div>

            <div className="info-block company-description">
              <div className="header-btn-container">
                <p className="info-block-label">Company description</p>
              </div>
              {userData.companyData?.description ? (
                <div>
                  <p className="info-block-label capIQ">Pulled from Cap IQ</p>
                  <TextField
                    className="capIQ-desc"
                    multiline
                    disabled
                    placeholder="This company description will appear in this company's report."
                    value={companyInfo.description || ''}
                  />
                  <div className="custom-desc-copy">
                    <p className="report">
                      <span>For this companies ASC 820 report</span>
                      <br />
                      We&apos;ve provided the Cap IQ company description above so you can see what was pulled from Cap IQ,
                      even if you choose to edit the company description below. The below will be used for the report.
                      You can leave it as is, or choose to edit it at any time.
                    </p>
                    <EditButton
                      isEditing={editingCompanyDesc}
                      setIsEditing={setEditingCompanyDesc}
                      infoSaving={companyDescSaving}
                      setInfoSaving={setCompanyDescSaving}
                      updateFunction={() => updateGenCompanyData()}
                    />
                  </div>
                  {editingCompanyDesc ? (
                    <TextField
                      className="edit-company-desc"
                      multiline
                      placeholder="This company description will appear in this company's report."
                      value={companyInfo.customDescription || ''}
                      onChange={(e) => setCompanyInfo({ ...companyInfo, customDescription: e.target.value })}
                    />
                  ) : (
                    <p id="non-textfield-description">{companyInfo.customDescription}</p>
                  )}
                </div>
              ) : (
                <>
                  <div className="custom-desc-block">
                    <div>
                      <Checkbox
                        className="capIQ-checkbox"
                        checked={customCompanyDescription}
                        onChange={(e) => {
                          setCustomCompanyDescription(e.target.checked);
                          if (!companyInfo.customDescription) {
                            setEditingCompanyDesc(true);
                          }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <span className="">
                        Check if&nbsp;
                        {companyInfo.portfolioCompanyName}
                        &nbsp;does not have a CapIq Identifier. If they don&apos;t you&apos;ll need to provide a company description.
                      </span>
                    </div>
                    {customCompanyDescription && (
                      <EditButton
                        isEditing={editingCompanyDesc}
                        setIsEditing={setEditingCompanyDesc}
                        infoSaving={companyDescSaving}
                        setInfoSaving={setCompanyDescSaving}
                        updateFunction={() => updateGenCompanyData()}
                      />
                    )}
                  </div>
                  {editingCompanyDesc && customCompanyDescription ? (
                    <TextField
                      className="no-capIQ"
                      multiline
                      maxRows={4}
                      placeholder="This company description will appear in this company's report."
                      value={companyInfo.customDescription || ''}
                      onChange={(e) => setCompanyInfo({ ...companyInfo, customDescription: e.target.value })}
                    />
                  ) : customCompanyDescription ? (
                    <p id="non-textfield-description">{userData.companyData.customDescription}</p>
                  ) : null}
                </>
              )}
            </div>
            <div className="info-block">
              <p className="info-block-label">Company specific notes</p>
              <Notes
                notesList={notesList}
                setNotesList={setNotesList}
                noteListLoading={noteListLoading}
              />
            </div>
          </AnimateHeight>
        </div>
      </div>
      <hr />
      <div className="info-dropdown">
        <div
          role="button"
          className="header-row"
          onClick={() => { setInfoDropdownHeight(infoDropdownHeight ? 0 : 'auto'); }}
          onKeyDown={(e) => { if (e.key === 'Enter') setInfoDropdownHeight(infoDropdownHeight ? 0 : 'auto'); }}
          tabIndex={0}
        >
          <IconButton
            className="chevron-btn"
            onClick={() => { setInfoDropdownHeight(infoDropdownHeight ? 0 : 'auto'); }}
          >
            <ExpandCircleDownOutlinedIcon
              className={`circle-chevron${infoDropdownHeight ? ' upward' : ' downward'}`}
            />
          </IconButton>
          <h5>Uploads</h5>
          <EditButton
            isEditing={editingUploads}
            setIsEditing={setEditingUploads}
            infoSaving={uploadsSaving}
            setInfoSaving={setUploadsSaving}
            updateFunction={() => updateGenCompanyData()}
          />
        </div>
        <div className="input-block">
          <AnimateHeight duration={500} height={infoDropdownHeight}>
            <div className="info-block">
              <div className="input-tiles">
                <div className="info-tile">
                  <p>Upload: Cap Table</p>
                  <UploadTile
                    uploadData={capTableUploads}
                    setUploadData={setCapTableUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Articles of incorporation</p>
                  <UploadTile
                    uploadData={articlesUploads}
                    setUploadData={setArticlesUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Option ledger</p>
                  <UploadTile
                    uploadData={optionLedgerUploads}
                    setUploadData={setOptionLedgerUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Secondary transaction documents</p>
                  <UploadTile
                    uploadData={secondaryTransactionUploads}
                    setUploadData={setSecondaryTransactionUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Term sheets</p>
                  <UploadTile
                    uploadData={termSheetsUploads}
                    setUploadData={setTermSheetsUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Safe or Convertible notes</p>
                  <UploadTile
                    uploadData={safeOrConvertibleNoteUploads}
                    setUploadData={setSafeOrConvertibleNoteUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Financial forecasts</p>
                  <UploadTile
                    uploadData={financialForecastUploads}
                    setUploadData={setFinancialForecastUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
                <div className="info-tile">
                  <p>Upload: Additional financials: (Balance sheets, income statements, etc.)</p>
                  <UploadTile
                    uploadData={additionalFinancialUploads}
                    setUploadData={setAdditionalFinancialUploads}
                    isEditing={editingUploads}
                    portfolioCompanyId={userData.metaData.portfolioCompanyId}
                    transactionId={userData.metaData.transactionId}
                  />
                </div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </main>
  );
}

GeneralInfo.propTypes = {
  enterpriseUsers: PropTypes.arrayOf(PropTypes.any),
};

GeneralInfo.defaultProps = {
  enterpriseUsers: [],
};
