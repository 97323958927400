import React, { useContext, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import {
  Avatar,
  AvatarGroup,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

import StatusBadgeDropdown from './components/StatusBadgeDropdown';

import { darkInitials } from '../../utils/globals';
import { copy, getInitials } from '../../utils';

import { UserContext, SubNavStateContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

import './CompanyCard.scss';

export default function CompanyCard({
  company,
  index,
  enterpriseUsers,
  setTransactionToView,
  projectCompanies,
  setProjectCompanies,
  investorFirmName,
  primaryInvestorId,
}) {
  const { userData, setUserData } = useContext(UserContext);
  const { subNavState, setSubNavState } = useContext(SubNavStateContext);
  const { dataEntry, preparer, reviewer } = company.assignees;
  const companyAssignees = [dataEntry, preparer, reviewer];
  const [insideElementHover, setInsideElementHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLocked, setIsLocked] = useState(parseInt(company.isLocked, 10));
  const [deletingCompany, setDeletingCompany] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(company.status);
  const [blockerStatus, setBlockerStatus] = useState(company.blockerStatus);
  const [projectStatus, setProjectStatus] = useState(null);
  const {
    transactionId,
    investorCompanyId,
    portfolioCompanyId,
    projectId,
  } = company;

  const companyData = {
    investorCompanyId,
    portfolioCompanyId,
    projectId,
    transactionId,
  };

  const [, lockOrUnlockTransactionRequest] = useFetch();
  const [, deleteTransactionRequest] = useFetch();

  async function updateTransactionLock(unlockOrLock) {
    if (unlockOrLock === 'lock') setCurrentStatus('820 Data entry');
    else setCurrentStatus('Client in-progress');
    setBlockerStatus('');
    lockOrUnlockTransactionRequest({
      url: `/transactions/asc820/enterprise-${unlockOrLock}-transaction`,
      method: 'post',
      body: companyData,
      bodyIds: ['enterpriseCompanyId', 'requestUserId'],
    });
  }

  async function deleteCompany() {
    deleteTransactionRequest({
      method: 'post',
      url: '/transactions/asc820/delete-transaction/',
      urlIds: ['enterpriseCompanyId', 'requestUserId'],
      body: companyData,
      bodyIds: ['enterpriseCompanyId', 'requestUserId'],
      onFinally: () => {
        const companiesCopy = copy(projectCompanies);
        companiesCopy.splice(index, 1);
        setProjectCompanies(companiesCopy);
        setDeletingCompany(false);
      },
    });
  }

  useEffect(() => {
    if (subNavState.status && subNavState.status.companyId === transactionId) {
      setCurrentStatus(subNavState.status.updateStatus);
      setBlockerStatus(subNavState.status.blockerStatus);
      setSubNavState({ ...subNavState, updateStatus: null, blockerStatus: null });
    }
  }, [subNavState.status]);

  const mappingOutput = {
    'Client not started 820': 'Data Entry',
    'Client in-progress': 'Data Entry',
    '820 Data entry': 'Data Entry',
    '820 Analysis': 'Prepare',
    '820 Review': 'Prepare',
    'Draft in-progress': 'Prepare',
    'Draft review': 'Prepare',
    'Draft delivered': 'Deliver',
    'Draft iteration': 'Deliver',
    'Draft audit': 'Deliver',
    'Financial statements ready': 'Deliver',
    '820 Done': 'Complete',
  };

  const orderedOutput = [
    'Client not started 820',
    'Client in-progress',
    '820 Data entry',
    '820 Analysis',
    '820 Review',
    'Draft in-progress',
    'Draft review',
    'Draft delivered',
    'Draft audit',
    'Draft iteration',
    'Financial statements ready',
    '820 Done',
  ];

  function determineProjectColumn() {
    const statuses = projectCompanies.map((comp) => {
      const compCopy = { ...comp };
      if (compCopy.transactionId === transactionId) compCopy.status = currentStatus; compCopy.blockerStatus = blockerStatus;
      const indexOfStatus = orderedOutput.indexOf(compCopy.status);
      return indexOfStatus;
    });
    const lowestStatusIndex = Math.min(...statuses);
    const status = orderedOutput[lowestStatusIndex];
    const column = mappingOutput[status];
    const updateProjectStatus = {
      projectId,
      status,
      column,
      blockerStatus,
    };
    setSubNavState({ ...subNavState, updateProjectStatus });
    setProjectStatus(false);
  }

  useEffect(() => { if (projectStatus) determineProjectColumn(); }, [projectStatus]);

  function assigneeAvatars() {
    if (companyAssignees.some((user) => user.accountId !== 'not assigned')) {
      return companyAssignees.map((assignee, idx) => {
        const foundAssignee = enterpriseUsers.find((u) => u.accountId === assignee.accountId);
        return (
          <Tooltip
            key={`${foundAssignee?.accountId}+=+=${idx + 1}`}
            disableInteractive
            title={(
              <Typography style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                {
                  foundAssignee?.firstName ?
                    `${foundAssignee?.firstName} ${foundAssignee?.lastName}\nAssignment: \
                        ${idx === 0 ? 'Data entry' : idx === 1 ? 'Preparer' : 'Reviewer'}` :
                    'Unassigned'
                }
              </Typography>
            )}
            PopperProps={{ className: 'bottom-arrow-tooltip' }}
            placement="bottom"
            arrow
          >
            <Avatar
              className={getInitials(`${foundAssignee?.firstName} ${foundAssignee?.lastName}`) === 'uu' ? 'unassigned' : ''}
              style={{
                backgroundColor: foundAssignee?.avatarColor,
                color: darkInitials.includes(foundAssignee?.avatarColor) ? '#49454F' : '#FAFAFD',
              }}
            >
              {
                getInitials(`${foundAssignee?.firstName} ${foundAssignee?.lastName}`) !== 'uu' ?
                  getInitials(`${foundAssignee?.firstName} ${foundAssignee?.lastName}`) :
                  <PermIdentityOutlinedIcon />
              }
            </Avatar>
          </Tooltip>
        );
      });
    }
    return new Array(3).fill(<Avatar className="unassigned"><PermIdentityOutlinedIcon /></Avatar>).map((avatar, idx) => (
      <Tooltip
        key={`${avatar + idx}`}
        disableInteractive
        title={(
          <Typography style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>Unassigned</Typography>
        )}
        PopperProps={{ className: 'bottom-arrow-tooltip' }}
        placement="bottom"
        arrow
      >
        {avatar}
      </Tooltip>
    ));
  }

  return (
    <div className="ProjectCompanyCard">
      <Draggable
        draggableId={company.resource.toString()}
        index={index}
        key={company.resource}
      >
        {(provided) => (
          <div
            role="button"
            className={`company-card${!insideElementHover ? ' can-hover' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            onClick={() => {
              if (!insideElementHover) {
                const projectCompanyData = projectCompanies.find((investorComp) => investorComp.transactionId === transactionId);
                setTransactionToView(projectCompanyData);
                setUserData({ ...userData, metaData: { ...projectCompanyData, investorFirmName, primaryInvestorId } });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const projectCompanyData = projectCompanies.find((investorComp) => investorComp.transactionId === transactionId);
                setTransactionToView(projectCompanyData);
                setUserData({ ...userData, metaData: { ...projectCompanyData, investorFirmName, primaryInvestorId } });
              }
            }}
            tabIndex={0}
          >
            <div className="company-info">
              <Avatar>{index + 1}</Avatar>
              <h6>{company.portfolioCompanyName}</h6>
            </div>
            <div
              className="company-status-info"
              onMouseEnter={() => { if (!insideElementHover) setInsideElementHover(true); }}
              onMouseLeave={() => setInsideElementHover(false)}
            >
              <StatusBadgeDropdown
                setInsideElementHover={setInsideElementHover}
                company={company}
                setProjectCompanies={setProjectCompanies}
                projectCompanies={projectCompanies}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
                blockerStatus={blockerStatus}
                setBlockerStatus={setBlockerStatus}
                setProjectStatus={setProjectStatus}
              />
              <AvatarGroup max={3} className={
                `assignee-avatar ${companyAssignees.every((user) => user.accountId !== 'not assigned') ? '' : 'hidden'}`
              }
              >
                {assigneeAvatars()}
              </AvatarGroup>
              <IconButton
                className={`menu-icon ${anchorEl ? 'menu-open' : ''}`}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                aria-haspopup="true"
              >
                <MoreVertOutlinedIcon />
              </IconButton>
              <Menu
                className="lock-transaction-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => {
                  setAnchorEl(null);
                  setTimeout(() => { setAreYouSure(false); }, 200);
                  setInsideElementHover(false);
                }}
                disableScrollLock
              >
                <MenuItem
                  className={`${isLocked ? '' : 'active'}`}
                  onClick={() => {
                    setIsLocked(0);
                    updateTransactionLock('unlock');
                    setAnchorEl(null);
                    setInsideElementHover(false);
                  }}
                >
                  <LockOpenOutlinedIcon />
                  &nbsp;
                  {isLocked ? 'Unlock so ' : 'Unlocked '}
                  client can edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsLocked(1);
                    updateTransactionLock('lock');
                    setAnchorEl(null);
                    setInsideElementHover(false);
                  }}
                  className={`${isLocked ? 'active' : ''}`}
                >
                  <LockOutlinedIcon />
                  &nbsp;
                  {isLocked ? 'Locked ' : 'Lock so '}
                  client can&apos;t edit
                </MenuItem>
                <MenuItem
                  className={areYouSure ? 'are-you-sure' : deletingCompany ? 'deleting-company' : ''}
                  onClick={() => setAreYouSure(!areYouSure)}
                >
                  {areYouSure ? (
                    <>
                      Are you sure?
                      <Button
                        onClick={() => {
                          setDeletingCompany(true);
                          setAreYouSure(!areYouSure);
                          deleteCompany();
                          setInsideElementHover(false);
                        }}
                      >
                        <CheckOutlinedIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          setAreYouSure(!areYouSure);
                          setInsideElementHover(false);
                        }}
                      >
                        <ClearOutlinedIcon />
                      </Button>
                    </>
                  ) : deletingCompany ? (
                    <>
                      <div className="dots-circle-spinner" />
                      Deleting company
                    </>
                  ) : (
                    <>
                      <DeleteOutlineOutlinedIcon style={{ marginRight: '0.25rem' }} />
                      Delete company
                    </>
                  )}
                </MenuItem>
              </Menu>
              <div
                className="drag-handle"
                // eslint-disable-next-line
                {...provided.dragHandleProps}
              >
                <DragHandleOutlinedIcon />
              </div>
            </div>
          </div>
        )
        }
      </Draggable>
    </div>
  );
}

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  enterpriseUsers: PropTypes.array.isRequired,
  setTransactionToView: PropTypes.func.isRequired,
  setProjectCompanies: PropTypes.func.isRequired,
  projectCompanies: PropTypes.array.isRequired,
  investorFirmName: PropTypes.string.isRequired,
  primaryInvestorId: PropTypes.string,
};

CompanyCard.defaultProps = {
  primaryInvestorId: '',
};
