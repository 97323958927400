import React, { useState, useCallback, useContext, useRef } from 'react';

import Button from '@mui/material/Button';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import Security from './Security';
import AccountInfo from './BasicInfo';

import { NavWidthContext } from '../../contexts';

import './ProfileSettings.scss';

export default function ProfileSettings() {
  const [tabSelected, setTabSelected] = useState('basic-info');
  const { setNavWidth } = useContext(NavWidthContext);

  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current) window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    }
  }, []);

  return (
    <main className="ProfileSettings" ref={appWidthRef}>
      <div className="profile-settings-container">
        <div className="top-tabs-nav">
          <Button
            className={`tab-button ${tabSelected === 'basic-info' ? 'active-tab' : ''}`}
            onClick={() => setTabSelected('basic-info')}
          >
            <PersonOutlineOutlinedIcon />
            Basic Info
          </Button>
          <Button
            className={`tab-button ${tabSelected === 'security' ? 'active-tab' : ''}`}
            onClick={() => setTabSelected('security')}
          >
            <SettingsOutlinedIcon />
            Security
          </Button>
        </div>
        {tabSelected === 'basic-info' && <AccountInfo />}
        {tabSelected === 'security' && <Security />}
      </div>
    </main>
  );
}
