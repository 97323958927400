import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import LinearProgress from '@mui/material/LinearProgress';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import CloseIcon from '@mui/icons-material/Close';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';

import { industryVerticals } from '../../../utils/globals';

import { millisecondsToStr } from '../../../utils';

import './AddCompModal.scss';

export default function AddCompModal({
  showAddCompModal,
  setShowAddCompModal,
  showIndustriesModal,
  setShowIndustriesModal,
  compsToAdd,
  setCompsToAdd,
  runComps,
  industryError,
  setIndustryError,
  runningComps,
  industries,
  setIndustries,
  searchIndustryInput,
  setSearchIndustryInput,
  currentIndustries,
  removeIndustryFromComp,
  compRunProgress,
  removingIndustry,
}) {
  const dialogScrollRef = useRef(null);

  const [noCompanyAdded, setNoCompanyAdded] = useState(false);

  const [rowsAdded, setRowsAdded] = useState(0);

  const [currentCompStep, setCurrentCompStep] = useState(-1);

  const [timeProgress, setTimeProgress] = useState(0);
  const [percentageProgress, setPercentageProgress] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const [compsSteps, setCompsSteps] = useState([
    'Validating public company list',
    'Generating requests for CapIQ',
    'Running ... requests through CapIQ',
    'Processing responses and running calculations',
    'Summarizing and analyzing company data',
    'Finalizing',
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (dialogScrollRef.current) {
        dialogScrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }, [compsToAdd]);

  useEffect(() => {
    if (compRunProgress.numberOfRequests) {
      setCompsSteps((prevCompsSteps) => {
        const newCompsSteps = [...prevCompsSteps];
        newCompsSteps[2] = `Running ${compRunProgress.numberOfRequests} requests through CapIQ`;
        return newCompsSteps;
      });
      setTotalTime(compRunProgress.timeEstimate + 5);
      setTimeProgress(0);
      setCurrentCompStep(1);
      setTimeout(() => {
        setCurrentCompStep(2);
        setTimeout(() => {
          setCurrentCompStep(3);
          setTimeout(() => {
            setCurrentCompStep(4);
            setTimeout(() => {
              setCurrentCompStep(5);
            }, 2000);
          }, 2000);
        }, compRunProgress.timeEstimate * 1000);
      }, 1000);
    }
  }, [compRunProgress]);

  const stepIcon = (stepIndex) => currentCompStep > stepIndex ? <DoneIcon /> : `${stepIndex + 1}`;

  function stepClassName(stepIndex) {
    let status = 'not-started';
    if (currentCompStep > stepIndex) status = 'completed';
    else if (currentCompStep === stepIndex) status = 'is-next';
    return status;
  }

  useEffect(() => {
    setCurrentCompStep(0);
    setTimeProgress(0);
    setTotalTime(0);
    setPercentageProgress(0);
    setCompsSteps((prevCompsSteps) => {
      const newCompsSteps = [...prevCompsSteps];
      newCompsSteps[2] = 'Running ... requests through CapIQ';
      return newCompsSteps;
    });
    if (runningComps) {
      const interval = setInterval(() => {
        setTimeProgress((prevTimeProgress) => {
          if (!totalTime || prevTimeProgress < totalTime) return (prevTimeProgress + 0.2);
          return prevTimeProgress;
        });
      }, 200);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [runningComps]);

  useEffect(() => {
    setPercentageProgress((prevPercentageProgress) => {
      let newProgress;
      if (!totalTime) newProgress = timeProgress;
      else if ((timeProgress / totalTime) * 100 > 100) newProgress = 100;
      else newProgress = ((timeProgress / totalTime) * 100);
      if (newProgress < parseFloat(prevPercentageProgress)) newProgress = parseFloat(prevPercentageProgress);
      return newProgress.toFixed(0);
    });
  }, [timeProgress, totalTime]);

  return (
    <Dialog
      open={showAddCompModal || showIndustriesModal || runningComps}
      className={`add-comp-modal ${showIndustriesModal ? 'industry' : ''} ${runningComps ? 'running-comps' : ''}`}
    >
      {runningComps ? (
        <>
          <div className="run-comps-tool-tip-header">
            <p>{`${compsSteps[currentCompStep].replace(`${compRunProgress.numberOfRequests} `, '')}...`}</p>
            {currentCompStep === 2 && <p>{`Estimated time: ${millisecondsToStr(compRunProgress.timeEstimate * 1000)}`}</p>}
          </div>
          <div className="linear-progress">
            <div className="linear-progress-header">
              <span>Status</span>
              <span className="current-step">{`${percentageProgress}%`}</span>
            </div>
            <LinearProgress variant="determinate" value={percentageProgress} />
          </div>
          <Stepper activeStep={currentCompStep} orientation="vertical">
            {compsSteps.map((step, i) => (
              <Step
                key={step}
                disabled={false}
                className="individual-step"
              >
                <StepLabel className={stepClassName(i)} StepIconComponent={() => stepIcon(i)}>
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      ) : (
        <>
          <div className="dialog-header">
            <AddBusinessOutlinedIcon />
            {showAddCompModal ? (
              <div className="header-text">
                <p>Add companies</p>
                <p>
                  Companies you add here will be added to both the Volatility tab and the Comps tab.
                  <br />
                  Companies added to the Comps tab will automatically be deselected for your convenience.
                </p>
              </div>
            ) : (
              <div className="header-text">
                <p>Add Industries</p>
                <p>
                  Companies in the industries you add will be added to both the Volatility and Comps tab.
                  <br />
                  Companies added to the Comps tab will automatically be deselected for your convenience.
                </p>
              </div>
            )}
          </div>
          {noCompanyAdded && (
            <div className="warning-block">
              <WarningRoundedIcon />
              <p>No companies have been added.</p>
            </div>
          )}

          <div className="add-companies-box">
            {showAddCompModal ? (
              <>
                <p>Type or copy and paste below. Add companies either by entering the company&apos;s Cap IQ identifier or Market and Ticker</p>
                <p>Example Cap IQ Identifier: IQ46952162, example Market:Ticker: NASDAQ:AMZN </p>
              </>
            ) : (
              <p>
                Add one or multiple industries. The more you add the longer the process can take, but don&apos;t worry,
                you&apos;ll be able to continue working as we add industries in the background.
              </p>
            )}
          </div>
          <div className="company-list-container">
            {runningComps && <div className="loading-overlay"><div className="dots-circle-spinner" /></div>}
            {showAddCompModal ? (
              <ol>
                {compsToAdd.map((compToAdd, index) => (
                  <li key={compToAdd.rowId}>
                    <TextField
                      className={`comp-list-input${compToAdd.isValid ? ' valid' : compToAdd.isValid === false ? ' invalid' : ''}`}
                      multiline
                      maxRows={1}
                      autoComplete="off"
                      autoFocus={index !== 0 && index === compsToAdd.length - 1}
                      placeholder={`${compsToAdd.length > 1 ? '' : 'One company per line'}`}
                      value={compToAdd.compInput}
                      disabled={runningComps}
                      onChange={(e) => {
                        const newCompList = [...compsToAdd];
                        if (!e.target.value.includes('\n')) {
                          newCompList[index] = { ...newCompList[index], compInput: e.target.value };
                        } else {
                          const pastedData = e.target.value.replace('\t', '').split('\n');
                          pastedData.forEach((pastedComp, i) => {
                            if (pastedComp) newCompList.splice(index + i, 0, { rowId: rowsAdded + index + i + 1, compInput: pastedComp.trim() });
                          });
                          if (newCompList[newCompList.length - 1].compInput) {
                            newCompList.push({ rowId: rowsAdded + pastedData.length + 1, compInput: '' });
                          }
                          setRowsAdded(rowsAdded + newCompList.length + 1);
                        }
                        setCompsToAdd(newCompList);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                        if (e.key === 'Enter' && index === compsToAdd.length - 1 && compToAdd.compInput) {
                          const newCompList = [...compsToAdd];
                          newCompList.push({ rowId: rowsAdded + 1, compInput: '' });
                          setRowsAdded(rowsAdded + 1);
                          setCompsToAdd(newCompList);
                        }
                        if (!e.target.value && e.key === 'Backspace' && compsToAdd.length > 1 && index !== compsToAdd.length - 1) {
                          setCompsToAdd([...compsToAdd.slice(0, index), ...compsToAdd.slice(index + 1)]);
                        }
                      }}
                      InputProps={{
                        startAdornment: compsToAdd[index].isValid ? (
                          <InputAdornment position="start">
                            <CheckCircleOutlineIcon />
                          </InputAdornment>
                        ) : compsToAdd[index].isValid === false && compsToAdd[index].compInput.length > 0 ? (
                          <InputAdornment position="start">
                            <ErrorOutlineIcon />
                          </InputAdornment>
                        ) : compsToAdd[index].isValidating && compsToAdd[index].compInput.length > 0 ? (
                          <InputAdornment position="start">
                            <div className="dots-circle-spinner" />
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                    {!runningComps && compsToAdd.length > 1 && index !== compsToAdd.length - 1 && (
                      <Button
                        className="remove-field-btn"
                        onClick={() => setCompsToAdd([...compsToAdd.slice(0, index), ...compsToAdd.slice(index + 1)])}
                      >
                        <RemoveIcon />
                        Remove
                      </Button>
                    )}
                  </li>
                ))}
              </ol>
            ) : (
              <div className="industries-select">
                {(industries || []).map((industry, index) => (
                  <div className="industry-dropdown" key={industry}>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      disabled={removingIndustry}
                      options={industryVerticals.filter((industryOption) => !industries.includes(industryOption))}
                      inputValue={searchIndustryInput[index] || ''}
                      onInputChange={(e, value) => {
                        const newIndustryArr = [...industries];
                        if (industryVerticals.find((val) => val === value)) {
                          newIndustryArr[index] = value;
                          setIndustries(newIndustryArr);
                          setSearchIndustryInput(newIndustryArr);
                        } else if (!value) {
                          newIndustryArr[index] = '';
                          setIndustries(newIndustryArr);
                          setSearchIndustryInput(newIndustryArr);
                          if (currentIndustries.includes(industries[index])) removeIndustryFromComp(industries[index]);
                        } else {
                          const newSearchIndustryArr = [...searchIndustryInput];
                          newSearchIndustryArr[index] = value;
                          setSearchIndustryInput(newSearchIndustryArr);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={industries[index] ? '' : <i>Industry</i>}
                          InputProps={{
                            ...params.InputProps,
                            ...industries.length > 1 && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setIndustries([...industries.slice(0, index), ...industries.slice(index + 1)]);
                                      setSearchIndustryInput([...searchIndustryInput.slice(0, index), ...searchIndustryInput.slice(index + 1)]);
                                      if (currentIndustries.includes(industries[index])) removeIndustryFromComp(industries[index]);
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            },
                          }}
                          onFocus={() => setIndustryError(false)}
                          error={industryError}
                          helperText={industryError ? (
                            <>
                              <ErrorIcon />
                              Please enter an industry or add companies
                            </>
                          ) : ' '}
                        />
                      )}
                    />
                  </div>
                ))}
                {industries.every((industryInput) => industryInput) && (
                  <IconButton className="industry-add-btn" onClick={() => setIndustries([...industries, ''])}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </div>
            )}
          </div>
          <div className="bottom-btns" ref={dialogScrollRef}>
            <Button
              className="cancel-btn"
              onClick={() => {
                setShowAddCompModal(false);
                setShowIndustriesModal(false);
                setNoCompanyAdded(false);
                setCompsToAdd([{ rowId: 0, compInput: '' }]);
              }}
            >
              <CloseIcon />
              Cancel
            </Button>
            <Button
              className="add-companies-btn"
              disabled={compsToAdd.some((comp) => comp.isValidating)}
              onClick={() => {
                if (showAddCompModal) {
                  if (compsToAdd.every((comp) => comp.compInput === '')) {
                    setNoCompanyAdded(true);
                  } else {
                    runComps();
                    setCurrentCompStep(0);
                    if (industryError) setIndustryError(false);
                  }
                }
                if (showIndustriesModal) {
                  if (industries[0].length === 0) {
                    setIndustryError(true);
                  } else {
                    setIndustryError(false);
                    runComps();
                    setCurrentCompStep(0);
                  }
                }
              }}
            >
              <AddBusinessOutlinedIcon />
              Add to company list
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
}

AddCompModal.propTypes = {
  showAddCompModal: PropTypes.bool.isRequired,
  setShowAddCompModal: PropTypes.func.isRequired,
  showIndustriesModal: PropTypes.bool.isRequired,
  setShowIndustriesModal: PropTypes.func.isRequired,
  compsToAdd: PropTypes.array.isRequired,
  setCompsToAdd: PropTypes.func.isRequired,
  runComps: PropTypes.func.isRequired,
  removingIndustry: PropTypes.bool.isRequired,
  setIndustryError: PropTypes.func.isRequired,
  industryError: PropTypes.bool.isRequired,
  runningComps: PropTypes.bool.isRequired,
  industries: PropTypes.array.isRequired,
  setIndustries: PropTypes.func.isRequired,
  searchIndustryInput: PropTypes.array.isRequired,
  setSearchIndustryInput: PropTypes.func.isRequired,
  currentIndustries: PropTypes.array.isRequired,
  removeIndustryFromComp: PropTypes.func.isRequired,
  compRunProgress: PropTypes.object.isRequired,
};
