import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import GeneralInfo from './GeneralInfo';
import ClientInputs from './ClientInputs';
import CapStructure from './CapStructure';
import Comps from './Comps';
import Calculations from './Calculations';
import PDFReport from './PDFReport';
import Holdings from './Holdings';

import { NavWidthContext, SubNavStateContext, UserContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

import { copy } from '../../utils';

import './index.scss';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function CalcInputs({ transactionToView, tabSelected, setTabSelected, enterpriseUsers }) {
  const [exitingToTab, setExitingToTab] = useState(false);
  const { userData, setUserData } = useContext(UserContext);

  const [loadingTransactionData, setLoadingTransactionData] = useState(true);

  const [runningComps, setRunningComps] = useState(false);
  const [compSuccess, setCompSuccess] = useState(false);

  const [saveCapData, setSaveCapData] = useState(false);

  const [checkForMissingCompInputs, setCheckForMissingCompInputs] = useState(false);

  const [generateNewReport, setGenerateNewReport] = useState({ type: null });
  const [selectedGridValue, setSelectedGridValue] = useState({ term: '', volatility: '' });

  const [currentBodyWidth, setCurrentBodyWidth] = useState(0);

  const { subNavState, setSubNavState } = useContext(SubNavStateContext);
  const { navWidth, setNavWidth } = useContext(NavWidthContext);

  const nav = useNavigate();

  const appWidth = useRef(null);

  const appWidthRef = useCallback((node) => {
    if (appWidth?.current) window.removeEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    if (node) {
      appWidth.current = node;
      window.addEventListener('resize', () => setNavWidth(appWidth?.current.scrollWidth));
    }
  }, []);

  const [{ response: transactionData, success: transactionDataFetchSuccess }] = useFetch({
    url: '/transactions/asc820/get-enterprise-transaction-data/',
    urlIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'userId'],
  });

  const [{ response: projectDetails, success: projectDetailsFetchSuccess }] = useFetch({
    url: '/projects/asc820/get-project-details/',
    urlIds: ['enterpriseCompanyId', 'investorCompanyId', 'projectId', 'userId'],
  });

  const [{ response: footnoteData, success: footnoteDataFetchSuccess }] = useFetch({
    url: '/footnotes/get-transaction-footnote/',
    urlIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'userId'],
  });

  const [{ response: capData, success: capDataFetchSuccess }] = useFetch({
    url: '/calc-engine/get-cap-structure-object/',
    urlIds: ['portfolioCompanyId', 'transactionId', 'userId'],
  });

  const [{ response: calcData, success: calcDataFetchSuccess }] = useFetch({
    url: '/calc-engine/get-calc-engine-object/',
    urlIds: ['portfolioCompanyId', 'transactionId', 'userId'],
  });

  const [{ response: gridData, success: gridDataFetchSuccess }] = useFetch({
    url: '/calcEngine/load-asc820-page/',
    urlIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'userId'],
    calcEngineCall: true,
  });

  useEffect(() => { window.addEventListener('resize', () => setCurrentBodyWidth(navWidth)); }, []);

  useEffect(() => {
    if (
      transactionDataFetchSuccess &&
      projectDetailsFetchSuccess &&
      footnoteDataFetchSuccess &&
      capDataFetchSuccess &&
      calcDataFetchSuccess &&
      gridDataFetchSuccess
    ) {
      const calcDataCopy = copy(calcData);
      if (!calcDataCopy?.limits?.term?.selectedTerm) {
        calcDataCopy.limits.term.selectedTerm = '3 years';
      } else {
        const selectedTerm = parseFloat(calcDataCopy.limits.term.selectedTerm.replace(/[^\d.]/g, ''));
        calcDataCopy.limits.term.selectedTerm = `${selectedTerm} year${selectedTerm > 1 ? 's' : ''}`;
      }
      if (!calcDataCopy?.limits?.volatility?.quantile || calcDataCopy?.limits?.volatility?.quantile === 'None') {
        calcDataCopy.limits.volatility.quantile = '50%';
      }
      if (!calcDataCopy?.limits?.term?.upperBuffer) calcDataCopy.limits.term.upperBuffer = '1';
      if (!calcDataCopy?.limits?.term?.lowerBuffer) calcDataCopy.limits.term.lowerBuffer = '1';
      if (!calcDataCopy?.limits?.volatility?.upperBuffer) calcDataCopy.limits.volatility.upperBuffer = '5';
      if (!calcDataCopy?.limits?.volatility?.lowerBuffer) calcDataCopy.limits.volatility.lowerBuffer = '5';
      if (!calcDataCopy?.methods?.backsolve?.marketAdjustment) calcDataCopy.methods.backsolve.marketAdjustment = '0%';
      else calcDataCopy.methods.backsolve.marketAdjustment = `${calcDataCopy.methods.backsolve.marketAdjustment.replaceAll('%', '')}%`;
      setUserData({
        ...userData,
        ...transactionData && { transactionData: transactionData.transactionData, companyData: transactionData.companyData },
        ...projectDetails && { projectDetails },
        ...footnoteData && { footnoteData },
        ...capData && { capData: capData.capStructure },
        ...calcData && { calcData: calcDataCopy },
        ...gridData && { gridData },
      });
      const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
      const paramTabToView = urlParamsOnPgLoad.get('tabSelected');
      if (paramTabToView) setTabSelected(paramTabToView);
      else setTabSelected('general-info');
      setLoadingTransactionData(false);
    }
  }, [
    transactionDataFetchSuccess,
    projectDetailsFetchSuccess,
    footnoteDataFetchSuccess,
    capDataFetchSuccess,
    calcDataFetchSuccess,
    gridDataFetchSuccess,
  ]);

  useEffect(() => {
    if (tabSelected) {
      const newURL = `?tabSelected=${tabSelected}` +
        `&pId=${transactionToView.projectId}` +
        `&tId=${transactionToView.transactionId}`;
      if (window.location.search !== newURL) nav(newURL);
      setSubNavState({
        ...subNavState,
        currentPage: 'calc-inputs',
        companyName: transactionToView.portfolioCompanyName,
        transactionToView,
      });
    }
  }, [tabSelected]);

  // eslint-disable-next-line no-console
  if (process.env.REACT_APP_ENV_LABEL === 'dev') console.log('userData', userData);

  if (loadingTransactionData) {
    return <div className="data-loading-spinner"><span className="dots-circle-spinner" /></div>;
  }

  return (
    <main className="CalcInputs" ref={appWidthRef}>
      <div className="top-tool-bar" style={{ minWidth: currentBodyWidth || navWidth }}>
        <Button disabled={saveCapData} onClick={() => { if (tabSelected === 'cap-structure') setSaveCapData(true); }}>
          {!saveCapData ? (
            <>
              <SaveOutlinedIcon />
              Save
            </>
          ) : (
            <>
              <span className="dots-circle-spinner" />
              Saving
            </>
          )}
        </Button>
      </div>
      <div className="top-tabs-nav" style={{ minWidth: currentBodyWidth || navWidth }}>
        {[
          { tabLabel: 'General info', tabName: 'general-info' },
          { tabLabel: '820 inputs', tabName: '820-inputs' },
          { tabLabel: 'Cap structure', tabName: 'cap-structure' },
          { tabLabel: 'Holdings', tabName: 'holdings' },
          { tabLabel: 'Industry', tabName: 'industry' },
          { tabLabel: 'Comps', tabName: 'comps' },
          { tabLabel: 'Calculations', tabName: 'calculations' },
          { tabLabel: 'ASC 820 report', tabName: 'report' },
        ].map(({ tabLabel, tabName }) => (
          <Button
            key={tabName}
            className={`tab-button${tabName === tabSelected ? ' active-tab' : ''} `}
            onClick={() => {
              if (tabSelected === '820-inputs' || tabSelected === 'cap-structure') setExitingToTab(tabName);
              else setTabSelected(tabName);
            }}
          >
            {tabLabel}
          </Button>
        ))}
      </div>
      <div className="tabs-content">
        {(tabSelected === 'general-info') && <GeneralInfo userData={userData} setUserData={setUserData} enterpriseUsers={enterpriseUsers} />}
        {tabSelected === '820-inputs' && (
          <ClientInputs
            userData={userData}
            setUserData={setUserData}
            checkForMissingCompInputs={checkForMissingCompInputs}
            setCheckForMissingCompInputs={setCheckForMissingCompInputs}
            exitingToTab={exitingToTab}
            setExitingToTab={setExitingToTab}
            setTabSelected={setTabSelected}
          />
        )}
        {tabSelected === 'cap-structure' && (
          <CapStructure
            userData={userData}
            saveCapData={saveCapData}
            setSaveCapData={setSaveCapData}
            setUserData={setUserData}
            setTabSelected={setTabSelected}
            exitingToTab={exitingToTab}
            setExitingToTab={setExitingToTab}
          />
        )}
        {tabSelected === 'holdings' && (
          <Holdings
            userData={userData}
            setUserData={setUserData}
            exitingToTab={exitingToTab}
            setExitingToTab={setExitingToTab}
            setTabSelected={setTabSelected}
          />
        )}
        {(tabSelected === 'industry' || tabSelected === 'comps') && (
          <Comps
            tabSelected={tabSelected}
            userData={userData}
            setUserData={setUserData}
            setTabSelected={setTabSelected}
            runningComps={runningComps}
            setRunningComps={setRunningComps}
            setCompSuccess={setCompSuccess}
            setCheckForMissingCompInputs={setCheckForMissingCompInputs}
            setSelectedGridValue={setSelectedGridValue}
          />
        )}
        {tabSelected === 'calculations' && (
          <Calculations
            userData={userData}
            setUserData={setUserData}
            setTabSelected={setTabSelected}
            selectedGridValue={selectedGridValue}
            setSelectedGridValue={setSelectedGridValue}
            setGenerateNewReport={setGenerateNewReport}
          />
        )}
        {tabSelected === 'report' && (
          <PDFReport
            userData={userData}
            setUserData={setUserData}
            generateNewReport={generateNewReport}
            setGenerateNewReport={setGenerateNewReport}
            selectedGridValue={selectedGridValue}
          />
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={compSuccess}
        onClose={() => setCompSuccess(false)}
        TransitionComponent={SlideTransition}
        message={(
          <>
            <span>Successfully ran comps.</span>
            <Button onClick={() => setCompSuccess(false)}> Dismiss</Button>
          </>
        )}
        autoHideDuration={5000}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      />
    </main>
  );
}

CalcInputs.propTypes = {
  transactionToView: PropTypes.object.isRequired,
  tabSelected: PropTypes.string,
  setTabSelected: PropTypes.func.isRequired,
  enterpriseUsers: PropTypes.arrayOf(PropTypes.object),
};

CalcInputs.defaultProps = {
  enterpriseUsers: [],
  tabSelected: '',
};
