import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import getCompsColumns from './compsTableColumns';


import './SummariesTable.scss';

export default function SummariesTable({
  tabSelected,
  compData,
  summariesData,
  reSummarizing,
  reSummarizeRequest,
  reSummarizeComplete,
  filters,
  summariesScrollRef,
  summariesOutdated,
}) {
  const rowNames = [
    'Maximum',
    '95th percentile',
    '90th percentile',
    '75th percentile',
    'Median',
    '25th percentile',
    '10th percentile',
    '5th percentile',
    'Minimum',
    'Average',
  ];

  return (
    <main className="SummariesTable">
      <div className="fixed-left">
        <div className="summaries-table-rows">
          {summariesData.length && rowNames.map((rowName) => (
            <div key={rowName} className="summaries-table-row-data">
              <div className="row-data-group summaries-list">
                <div className="table-cell">{rowName}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="right-scrollable">
        {(summariesOutdated || reSummarizing) && (
          <div className="loading-overlay">
            {summariesOutdated && (
              <Button className={reSummarizing ? 'loading' : reSummarizeComplete ? 'complete' : ''} onClick={reSummarizeRequest}>
                {reSummarizing ? (
                  <>
                    <span className="dots-circle-spinner" />
                    Re-summarizing...
                  </>
                ) : reSummarizeComplete ? (
                  <>
                    <CheckCircleOutlineOutlinedIcon />
                    Resummary complete
                  </>
                ) : (
                  <>
                    <RefreshIcon />
                    Re-summarize
                  </>
                )}
              </Button>
            )}
          </div>
        )}
        <div className="summaries-table-rows" ref={summariesScrollRef}>
          {summariesData.length && rowNames.map((rowName, rowIndex) => (
            <div key={rowName} className="summaries-table-row-data">
              {getCompsColumns(compData, tabSelected, filters)
                .map((columnGroup) => (
                  <div key={columnGroup.columnGroupName} className="row-data-group">
                    {columnGroup.columnGroupChildren.map((column) => (
                      <div
                        key={column.field}
                        className="table-cell"
                        style={{ minWidth: `${column.width}px` }}
                      >
                        {column.format ? column.format(summariesData[rowIndex][column.field]) : summariesData[rowIndex][column.field] || '-'}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}

SummariesTable.propTypes = {
  tabSelected: PropTypes.string.isRequired,
  reSummarizing: PropTypes.bool.isRequired,
  summariesOutdated: PropTypes.bool.isRequired,
  reSummarizeRequest: PropTypes.func.isRequired,
  reSummarizeComplete: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  compData: PropTypes.array.isRequired,
  summariesData: PropTypes.array.isRequired,
  summariesScrollRef: PropTypes.func.isRequired,
};
