import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import CloseIcon from '@mui/icons-material/Close';
import InputIcon from '@mui/icons-material/Input';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import useFetch from '../../hooks/useFetch';

import { copy, commaEvery3rdChar, formatSeriesName } from '../../utils';

import './Calculations.scss';
import useS3 from '../../hooks/useS3';

export default function Calculations({
  userData,
  setUserData,
  setTabSelected,
  setGenerateNewReport,
  selectedGridValue,
  setSelectedGridValue,
}) {
  const [calcData, setCalcData] = useState(null);
  const [gridData, setGridData] = useState(null);

  // const [showMAndA, setShowMAndA] = useState(false);

  const [showFootnotes, setShowFootnotes] = useState(false);

  const [backsolveWeight, setBacksolveWeight] = useState('100%');
  const [pubCoWeight, setPubCoWeight] = useState('0%');
  const [mnaWeight, setMnaWeight] = useState('0%');
  const [methodWeightWasChanged, setMethodWeightWasChanged] = useState(false);

  const [gridValueSelected, setGridValueSelected] = useState(false);

  const [calcInProgress, setCalcInProgress] = useState(false);
  const [calcComplete, setCalcComplete] = useState(false);
  const [showWeightNotification, setShowWeightNotification] = useState(false);

  const [termIncrements, setTermIncrements] = useState(['0.5 year', '1 year', '1.5 years', '2 years', '2.5 years', '3 years']);
  const [volIncrements, setVolIncrements] = useState(['1%', '2.5%', '5%', '10%']);

  const [termMinBufferValues, setTermMinBufferValues] = useState(['0.5 year', '1 year', '1.5 years', '2 years', '2.5 years', '3 years']);

  const [termChanged, setTermChanged] = useState(false);
  const [termRangeChanged, setTermRangeChanged] = useState(false);
  const [volRangeChanged, setVolRangeChanged] = useState(false);

  const [gridSelectionCentered, setGridSelectionCentered] = useState(true);
  const [warningCopy, setWarningCopy] = useState('');

  const [revertFootnoteState, setRevertFootnoteState] = useState(false);

  const [summariesData, setSummariesData] = useState({});

  const gridHeaderScroll = useRef(null);
  const gridRightScroll = useRef(null);
  const gridLeftScroll = useRef(null);

  const [, runMethodsRequest] = useFetch();
  const [, runASC820Request] = useFetch();

  // TEMPORARY S3 CALL TO GET SUGGESTED MARKET ADJUSTMENT
  const [, summaryDataRequest] = useFetch();

  const [{ response: summaryS3Location }, getS3SummaryRequest] = useS3();

  useEffect(() => {
    if (summaryS3Location) {
      summaryDataRequest({ url: summaryS3Location, s3Call: true, onSuccess: setSummariesData });
    }
  }, [summaryS3Location]);

  function getSummaryRequest() {
    const s3Bucket = `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`;
    if (userData?.transactionData?.compSummaryLocation) {
      getS3SummaryRequest({
        key: userData?.transactionData?.compSummaryLocation.replace(`s3://${s3Bucket}/820Comps`, ''),
        customPrefix: '820Comps',
        requestType: 'get',
      });
    }
  }

  useEffect(() => { getSummaryRequest(); }, [userData?.transactionData?.compSummaryLocation]);

  function getSuggestedMarketAdjustment() {
    if (Object.keys(summariesData).length > 0) {
      const suggestedMarketAdjustment = `${(summariesData[5]['Market Cap Growth'] * 100).toFixed(2)}%`;
      setUserData({
        ...userData,
        calcData: {
          ...userData.calcData,
          methods: {
            ...userData.calcData.methods,
            backsolve: {
              ...userData.calcData.methods.backsolve,
              suggestedMarketAdjustment,
            },
          },
        },
      });
    }
  }

  useEffect(() => { getSuggestedMarketAdjustment(); }, [summariesData]);

  const volatilityLabels = [
    { value: 'min', label: 'Minimum' },
    { value: '5%', label: '5th percentile' },
    { value: '10%', label: '10th percentile' },
    { value: '25%', label: '25th percentile' },
    { value: '50%', label: 'Median' },
    { value: '75%', label: '75th percentile' },
    { value: '90%', label: '90th percentile' },
    { value: '95%', label: '95th percentile' },
    { value: 'max', label: 'Maximum' },
    { value: 'mean', label: 'Average' },
  ];

  function handleGridScroll() {
    gridHeaderScroll.current.scrollLeft = gridRightScroll.current.scrollLeft;
    gridLeftScroll.current.scrollTop = gridRightScroll.current.scrollTop;
  }

  const gridRightRef = useCallback((node) => {
    if (gridRightScroll?.current) gridRightScroll.current.removeEventListener('scroll', handleGridScroll);
    if (node) node.addEventListener('scroll', handleGridScroll, { passive: true });
    gridRightScroll.current = node;
  }, []);

  function calculateCenter() {
    const isCentered = userData.gridData?.grid[0].some((selection) => selection[1] === parseFloat(selectedGridValue.volatility) &&
      selection[0] === parseFloat(selectedGridValue.price));
    if (!isCentered) {
      const currentGridData = userData.gridData?.grid;
      const currentVolatilitySelection = parseFloat(Object.keys(currentGridData)
        .find((key) => currentGridData[key].some((selection) => selection[1] === parseFloat(selectedGridValue.volatility) &&
          selection[0] === parseFloat(selectedGridValue.price))));
      setWarningCopy(
        `${parseFloat(Math.abs(currentVolatilitySelection))}% ${currentVolatilitySelection < 0 ? 'less' : 'more'} \
        than the ${volatilityLabels.find((vol) => vol.value === userData.calcData?.limits.volatility.quantile)?.label}`,
      );
    }
    setGridSelectionCentered(isCentered);
  }

  useEffect(() => {
    if (termChanged) {
      const selectedTerm = parseFloat(userData.calcData.limits.term.selectedTerm.replace(/[^\d.]/g, '').trim());
      const bufferVals = ['0.5 year', '1 year', '1.5 years', '2 years', '2.5 years', '3 years'];
      if (selectedTerm <= 0.5) bufferVals.splice(0);
      if (selectedTerm <= 1) bufferVals.splice(1);
      if (selectedTerm <= 1.5) bufferVals.splice(2);
      if (selectedTerm <= 2) bufferVals.splice(3);
      if (selectedTerm <= 2.5) bufferVals.splice(4);
      if (selectedTerm <= 3) bufferVals.splice(5);
      if (!bufferVals.map((buffer) => buffer.replace(/[^\d.]/g, '').trim()).includes(userData.calcData?.limits.term.lowerBuffer)) {
        const newData = copy(userData.calcData);
        newData.limits.term.lowerBuffer = bufferVals.map((buffer) => buffer.replace(/[^\d.]/g, '').trim())[bufferVals.length - 1] || '';
        setUserData({ ...userData, calcData: newData });
      }
      setTermMinBufferValues(bufferVals);
      setTermChanged(false);
    }
  }, [termChanged]);

  useEffect(() => {
    if (termRangeChanged) {
      const { upperBuffer, lowerBuffer } = userData.calcData.limits.term;
      const increments = [...Array(5)].map((_, index) => `${(index / 4)} year`);
      increments.shift();
      if (upperBuffer === '0.25' || lowerBuffer === '0.25') increments.splice(1);
      else if (upperBuffer === '0.5' || lowerBuffer === '0.5') increments.splice(2);
      else if (upperBuffer === '0.75' || lowerBuffer === '0.75') increments.splice(3);
      if (!increments.map((increment) => increment.replace(/[^\d.]/g, '').trim()).includes(userData.calcData?.limits.term.increment)) {
        const newData = copy(userData.calcData);
        newData.limits.term.increment = increments.map((increment) => increment.replace(/[^\d.]/g, '').trim())[increments.length - 1];
        setUserData({ ...userData, calcData: newData });
      }
      setTermIncrements(increments);
      setTermRangeChanged(false);
    }
  }, [termRangeChanged]);

  useEffect(() => {
    if (volRangeChanged) {
      const { upperBuffer, lowerBuffer } = userData.calcData.limits.volatility;
      const increments = ['1%', '2.5%', '5%', '10%'];
      if (upperBuffer === '5' || lowerBuffer === '5') increments.splice(3);
      if (!increments.map((increment) => increment.replace('%', '')).includes(userData.calcData?.limits.volatility.increment)) {
        const newData = copy(userData.calcData);
        newData.limits.volatility.increment = increments.map((increment) => increment.replace('%', ''))[increments.length - 1];
        setUserData({ ...userData, calcData: newData });
      }
      setVolIncrements(increments);
      setVolRangeChanged(false);
    }
  }, [volRangeChanged]);

  useEffect(() => {
    if (userData.gridData?.defaultScenarios) {
      const calcDataCopy = copy(userData.gridData.defaultScenarios);
      if (calcDataCopy.backsolve) {
        calcDataCopy.backsolve.perShareConclusion['Ownership Class'] =
          calcDataCopy.backsolve.perShareConclusion['Ownership Class'].map((shareClass) => formatSeriesName(shareClass));
      }
      if (calcDataCopy.pubco) {
        calcDataCopy.pubco.perShareConclusion['Ownership Class'] =
          calcDataCopy.pubco.perShareConclusion['Ownership Class'].map((shareClass) => formatSeriesName(shareClass));
      }
      if (calcDataCopy.mna) {
        calcDataCopy.mna.perShareConclusion['Ownership Class'] =
          calcDataCopy.mna.perShareConclusion['Ownership Class'].map((shareClass) => formatSeriesName(shareClass));
      }
      if (calcDataCopy.concluded) {
        calcDataCopy.concluded.object['Class of stock'] =
          calcDataCopy.concluded.object['Class of stock'].map((shareClass) => formatSeriesName(shareClass));
      }
      setCalcData(calcDataCopy);
      setGridData(userData.gridData.grid);
      setBacksolveWeight(`${userData.gridData.backsolveWeight * 100}%`);
      setPubCoWeight(`${userData.gridData.pubcoWeight * 100}%`);
      setMnaWeight(`${userData.gridData.mnaWeight * 100}%`);
      setTermRangeChanged(true);
      setVolRangeChanged(true);
      setTermChanged(true);
    }
    if (!selectedGridValue.term || !selectedGridValue.volatility) {
      setSelectedGridValue({
        term: userData.gridData?.defaultScenarios?.middleTerm,
        volatility: userData.gridData?.defaultScenarios?.middleVolatility.toFixed(1),
      });
    }
  }, [userData.gridData]);

  function calculateTotalScenarios() {
    let totalScenarios;
    if (userData.gridData?.totalScenariosRun) {
      if (userData.gridData?.defaultScenarios?.backsolveEquityValue &&
        userData.gridData?.defaultScenarios?.mnaEquityValue &&
        userData.gridData?.defaultScenarios?.pubcoEquityValue) {
        totalScenarios = userData.gridData.totalScenariosRun * 3;
      } else if (userData.gridData?.defaultScenarios?.backsolveEquityValue &&
        userData.gridData?.defaultScenarios?.pubcoEquityValue) {
        totalScenarios = userData.gridData.totalScenariosRun * 2;
      } else {
        totalScenarios = userData.gridData?.totalScenariosRun;
      }
      return totalScenarios;
    }
    return null;
  }

  function runMethods() {
    const methodsData = {
      weights: {
        backsolve: backsolveWeight ? parseFloat(backsolveWeight.replace('%', '')) : 100,
        pubco: pubCoWeight ? parseFloat(pubCoWeight.replace('%', '')) : 0,
        mna: mnaWeight ? parseFloat(mnaWeight.replace('%', '')) : 0,
      },
      term: selectedGridValue.term,
      volatility: selectedGridValue.volatility,
    };
    runMethodsRequest({
      url: '/calcEngine/weigh-asc820-methods',
      method: 'post',
      body: methodsData, bodyIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
      calcEngineCall: true,
      onSuccess: (responseData) => {
        setUserData({
          ...userData,
          gridData: {
            ...responseData,
            calculationTime: userData.gridData?.calculationTime,
            totalScenariosRun: userData.gridData?.totalScenariosRun,
            grid: gridData,
          },
        });
      },
    });
  }

  const [, updateCalcObjectRequest] = useFetch();
  const [, revertFootnoteRequest] = useFetch();
  const [, saveFootnoteRequest] = useFetch();

  function runASC820() {
    setCalcInProgress(true);
    const calcDataCopy = copy(userData.calcData);
    calcDataCopy.limits.volatility.calculateRange = 1;
    calcDataCopy.limits.term.calculateRange = 1;
    calcDataCopy.limits.term.selectedTerm = new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 })
      .format(userData.calcData.limits.term.selectedTerm.replace(/[^\d.]/g, '').trim());
    calcDataCopy.methods.backsolve.marketAdjustment = parseFloat(calcDataCopy.methods.backsolve.marketAdjustment.replace('%', ''));
    calcDataCopy.methods.backsolve.weight = backsolveWeight ? parseFloat(backsolveWeight.replace('%', '')) : 100;
    calcDataCopy.methods.pubCo.weight = pubCoWeight ? parseFloat(pubCoWeight.replace('%', '')) : 0;
    calcDataCopy.methods.mna.weight = mnaWeight ? parseFloat(mnaWeight.replace('%', '')) : 0;
    if (!calcDataCopy.limits.term.lowerBuffer) calcDataCopy.limits.term.lowerBuffer = '0';
    updateCalcObjectRequest({
      url: '/calc-engine/update-calc-engine-object',
      method: 'post',
      body: calcDataCopy,
      onError: () => setCalcInProgress(false),
      onSuccess: () => {
        const methodDBData = {
          portfolioCompanyId: userData.metaData.portfolioCompanyId,
          transactionId: userData.metaData.transactionId,
          methods: ['backsolve', 'pubco', 'mna'],
        };
        runASC820Request({
          url: '/calcEngine/run_asc820',
          method: 'post',
          body: methodDBData,
          bodyIds: ['enterpriseCompanyId', 'requestUserId'],
          calcEngineCall: true,
          onFinally: () => setCalcInProgress(false),
          onSuccess: (responseData) => {
            const updatedCalcObject = responseData.calcObject;
            const selectedTerm = parseFloat(updatedCalcObject?.limits?.term?.selectedTerm);
            updatedCalcObject.limits.term.selectedTerm = `${selectedTerm} year${selectedTerm > 1 ? 's' : ''}`;
            updatedCalcObject.methods.backsolve.marketAdjustment = `${updatedCalcObject.methods.backsolve.marketAdjustment}%`;
            setUserData({ ...userData, calcData: updatedCalcObject, gridData: responseData });
            setSelectedGridValue({
              term: parseFloat(responseData.defaultScenarios?.middleTerm),
              volatility: responseData.defaultScenarios?.middleVolatility.toFixed(1),
            });
            setCalcComplete(true);
            setTimeout(() => setCalcComplete(false), 5000);
          },
        });
      },
    });
  }

  function revertFootnote(data, dataName) {
    setRevertFootnoteState(dataName);
    const footnoteObject = {
      methodsFootnotes: [],
      payload: data,
      dataName,
      ...userData.gridData.defaultScenarios.term && userData.gridData.defaultScenarios.volatility ? {
        term: new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 1,
        }).format(userData.gridData.defaultScenarios.term),
        volatility: userData.gridData.defaultScenarios.volatility,
      } : {
        term: new Intl.NumberFormat('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 1,
        }).format(userData.gridData.defaultScenarios.term),
        volatility: userData.gridData.defaultScenarios.volatility.toFixed(1).toString(),
      },
    };
    revertFootnoteRequest({
      url: '/footnotes/dynamic-footnotes',
      method: 'post',
      body: footnoteObject,
      bodyIds: ['portfolioCompanyId', 'enterpriseCompanyId', 'transactionId', 'requestUserId'],
      onSuccess: (responseData) => {
        setUserData({ ...userData, footnoteData: { ...userData.footnoteData, [dataName]: responseData[dataName] } });
      },
      onFinally: () => setRevertFootnoteState(false),
    });
  }

  function saveFootnotes(data, dataName) {
    const footnoteData = {
      methodsFootnotes: [],
      payload: data,
      dataName,
    };
    saveFootnoteRequest({
      url: '/footnotes/dynamic-footnotes',
      method: 'post',
      body: footnoteData,
      bodyIds: ['enterpriseCompanyId', 'portfolioCompanyId', 'transactionId', 'requestUserId'],
    });
  }

  useEffect(() => {
    if (gridValueSelected) {
      calculateCenter();
      runMethods();
      setGridValueSelected(false);
    }
  }, [gridValueSelected]);

  useEffect(() => {
    if (methodWeightWasChanged) {
      if ((parseFloat(backsolveWeight.replace('%', '')) || 0) +
        (parseFloat(pubCoWeight.replace('%', '')) || 0) +
        (parseFloat(mnaWeight.replace('%', '')) || 0) !== 100) {
        setShowWeightNotification(true);
      } else {
        runMethods();
        setShowWeightNotification(false);
      }
    }
    setMethodWeightWasChanged(false);
  }, [methodWeightWasChanged]);

  function determineMethod() {
    const backsolve = !!userData?.gridData?.defaultScenarios?.backsolve;
    const pubCo = !!userData?.gridData?.defaultScenarios?.pubco;
    const mna = !!userData?.gridData?.defaultScenarios?.mna;
    return `${backsolve ? 'Backsolve' : ''}${pubCo ? ' + PubCo' : ''}${mna ? ' + M&A' : ''}`;
  }

  return (
    <div className="Calculations">
      <div className="generate-report-button">
        <Button
          disabled={!gridSelectionCentered || !userData.gridData?.defaultScenarios}
          onClick={() => {
            setTabSelected('report');
            setGenerateNewReport({ type: 'draft', withFootnotes: true });
          }}
        >
          <MenuBookOutlinedIcon />
          Generate draft report
        </Button>
      </div>
      <div className="header-info-block">
        <h4>
          <CalculateOutlinedIcon />
          Calculations
        </h4>
        {(!userData.capData?.preferred && !userData.capData?.safeConvertible) ||
          !userData.transactionData?.compsLocation ?
          (
            <>
              <p>This Calculations tab is not yet available.&nbsp;</p>
              <p>It will be available and you will be able to run Backsolve, PubCo and M&A methods once:</p>
              <ul>
                <li>required information has been entered into the cap structure tab,</li>
                <li>and comps have been run</li>
              </ul>
            </>
          ) : (
            <div className="header-content">
              <p>
                The total weight at the bottom of the comps tab must equal 100% to run the calc engine. When ready, run shorter
                <br />
                ranges, and bigger increments, to save calculation time. We provide you with the most recent calculation&apos;s time, plus
                <br />
                the total number of scenarios ran, to help give you a feel for how long future calculations may take.
              </p>
              <div className="info-block">
                <AccessTimeOutlinedIcon />
                <h5>Calculation time</h5>
                <span className="user-data">
                  {userData.gridData?.calculationTime ?
                    `${Math.round(userData.gridData.calculationTime, 0)} seconds` : '-'}
                </span>
              </div>
              <div className="info-block">
                <span>#</span>
                <h5>Total scenarios ran</h5>
                <span className="user-data">
                  {calculateTotalScenarios() ? commaEvery3rdChar(calculateTotalScenarios()) : '-'}
                </span>
              </div>
            </div>
          )}
      </div>
      {(!userData.capData?.preferred && !userData.capData?.safeConvertible) ||
        !userData.transactionData?.compsLocation ||
        !userData.transactionData?.holdings ?
        (
          <p className="no-calc-text">Calc engine inputs and outputs will show here.</p>
        ) : (
          <div className="calc-wrapper">
            <div className="calc-header">
              <h4>
                <ScaleOutlinedIcon />
                Weight methods & run the calc engine
              </h4>
              <p>
                Run the calc engine to see calculated, weighted values. You can edit volatility below or from the volatility tab.
                <br />
                Changing it here will automatically change it on the volatility tab.
              </p>
              <Button
                className="run-calc-btn"
                disabled={showWeightNotification || calcInProgress}
                onClick={() => { runASC820(); }}
              >
                <CalculateOutlinedIcon />
                Run calc engine
              </Button>
            </div>
            {calcInProgress && (
              <div className="calc-in-progress">
                <span className="dots-circle-spinner" />
                <span>
                  Calculation in progress. It can take a few minutes.
                  If you make edits during calculation, you&apos;ll need to recalculate.
                </span>
                <IconButton
                  className="close-icon"
                  onClick={() => setCalcInProgress(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            {calcComplete && !calcInProgress && (
              <div className="calc-complete">
                <div className="check-icon"><CheckRoundedIcon /></div>
                <span>Calculation complete. Calc engine outputs have been updated.</span>
                <IconButton
                  className="close-icon"
                  onClick={() => setCalcComplete(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            <AnimateHeight duration={600} height={showWeightNotification ? 'auto' : 0}>
              <div className="weight-notification">
                <NotificationImportantOutlinedIcon />
                <span>Weights must equal 100%</span>
              </div>
            </AnimateHeight>
            <div className="calc-content">
              <div className="inputs-wrapper">
                <div className="input-section">
                  <h6>
                    <InputIcon />
                    Define inputs
                  </h6>
                  <div className="market-adjust">
                    <div className="input-block">
                      <h6>Market equity adjustment</h6>
                      <TextField
                        value={userData.calcData?.methods.backsolve.marketAdjustment || '0%'}
                        onChange={(e) => {
                          const newData = copy(userData.calcData);
                          newData.methods.backsolve.marketAdjustment = e.target.value;
                          setUserData({ ...userData, calcData: newData });
                        }}
                        onBlur={(e) => {
                          const newData = copy(userData.calcData);
                          newData.methods.backsolve.marketAdjustment = `${parseFloat(e.target.value)}%`;
                          setUserData({ ...userData, calcData: newData });
                          setTermChanged(true);
                        }}
                      />
                    </div>
                    <div>
                      <h6>Suggested market equity adjustment</h6>
                      <TextField
                        className="suggested-market-adjustment"
                        value={userData.calcData?.methods?.backsolve?.suggestedMarketAdjustment || ''}
                        disabled
                        InputProps={{
                          startAdornment: (
                            <TipsAndUpdatesOutlinedIcon />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="vl" />
                <div className="input-section">
                  <h6>
                    <ScaleOutlinedIcon />
                    Weight methods
                  </h6>
                  <div className="weights-inputs">
                    <div className="weight-input-block">
                      <h6>Backsolve method</h6>
                      <TextField
                        label="Weight"
                        value={backsolveWeight || ''}
                        onChange={(e) => setBacksolveWeight(e.target.value)}
                        onBlur={(e) => {
                          setBacksolveWeight(!e.target.value.includes('%') ? `${e.target.value}%` : e.target.value);
                          setMethodWeightWasChanged(true);
                        }}
                      />
                    </div>
                    <div className="vl" />
                    <div className="weight-input-block">
                      <h6>Guideline public companies (GPC) method</h6>
                      <TextField
                        label="Weight"
                        value={pubCoWeight || ''}
                        onChange={(e) => setPubCoWeight(e.target.value)}
                        onBlur={(e) => {
                          setPubCoWeight(!e.target.value.includes('%') ? `${e.target.value}%` : e.target.value);
                          setMethodWeightWasChanged(true);
                        }}
                      />
                    </div>
                    {/* <div className="vl" />
                <div className="weight-input-block">
                  <h6>M&A</h6>
                  <TextField
                    label="Weight"
                    value={mnaWeight || ''}
                    onChange={(e) => setMnaWeight(e.target.value)}
                    onBlur={(e) => {
                      setMnaWeight(!e.target.value.includes('%') ? `${e.target.value}%` : e.target.value);
                      setMethodWeightWasChanged(true);
                    }}
                  />
                </div> */}
                  </div>
                </div>
              </div>
              <div className="sensitivity-analysis-wrapper">
                <div className="sensitivity-analysis">
                  <h6 className="range-inputs-header">Ranges for sensitivity analysis</h6>
                  <div className="range-inputs">
                    <div className="vl" />
                    <div className="term-val">
                      <div className="input-title-and-icon">
                        <h6>Term - valuation date</h6>
                        <Tooltip
                          disableInteractive
                          title="Also known as 'Valuation date time to exit' or 'Allocation term'."
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="top"
                          arrow
                        >
                          <div className="round-icon-wrapper"><ErrorIcon /></div>
                        </Tooltip>
                      </div>
                      <div>
                        <TextField
                          value={userData.calcData?.limits?.term.selectedTerm || ''}
                          onChange={(e) => {
                            const newData = copy(userData.calcData);
                            newData.limits.term.selectedTerm = e.target.value;
                            setUserData({ ...userData, calcData: newData });
                          }}
                          onBlur={(e) => {
                            const newData = copy(userData.calcData);
                            let formattedNum = new Intl.NumberFormat('en-US').format(e.target.value.replace(/[^\d.]/g, '').trim());
                            if (parseFloat(formattedNum) > 10) formattedNum = '10';
                            newData.limits.term.selectedTerm = `${formattedNum} year${parseFloat(formattedNum) > 1 ? 's' : ''}`;
                            setUserData({ ...userData, calcData: newData });
                            setTermChanged(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="term-input">
                      <h6>Term range</h6>
                      <div>
                        <TextField
                          select
                          InputLabelProps={{ shrink: true }}
                          label={(
                            <>
                              <span>
                                Buffer (-)&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                              <Tooltip
                                disableInteractive
                                title="Lower buffer in the sensitivity table is the sensitivity range before the selected term"
                                PopperProps={{ className: 'bottom-arrow-tooltip centered-text' }}
                                placement="top"
                                arrow
                              >
                                <div className={`round-icon-wrapper${!termMinBufferValues.length ? ' disabled' : ''}`}><ErrorIcon /></div>
                              </Tooltip>
                              &nbsp;
                            </>
                          )}
                          disabled={!termMinBufferValues.length}
                          value={userData.calcData.limits.term.lowerBuffer || ''}
                          onChange={(e) => {
                            const newData = copy(userData.calcData);
                            newData.limits.term.lowerBuffer = e.target.value;
                            setUserData({ ...userData, calcData: newData });
                            setTermRangeChanged(true);
                          }}
                          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                        >
                          {termMinBufferValues.map((range) => (
                            <MenuItem key={range} value={`${range.replace(/[^\d.]/g, '').trim()}`}>{range}</MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          select
                          InputLabelProps={{ shrink: true }}
                          label={(
                            <>
                              <span>
                                Buffer (-)&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                              <Tooltip
                                disableInteractive
                                title="Higher buffer in the sensitivity table is the sensitivity range after the selected term"
                                PopperProps={{ className: 'bottom-arrow-tooltip centered-text' }}
                                placement="top"
                                arrow
                              >
                                <div className="round-icon-wrapper"><ErrorIcon /></div>
                              </Tooltip>
                              &nbsp;
                            </>
                          )}
                          value={userData.calcData?.limits?.term.upperBuffer || ''}
                          onChange={(e) => {
                            const newData = copy(userData.calcData);
                            newData.limits.term.upperBuffer = e.target.value;
                            setUserData({ ...userData, calcData: newData });
                            setTermRangeChanged(true);
                          }}
                          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                        >
                          {['0.5 year', '1 year', '1.5 years', '2 years', '2.5 years', '3 years'].map((range) => (
                            <MenuItem key={range} value={`${range.replace(/[^\d.]/g, '').trim()}`}>{range}</MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          select
                          label="Increment"
                          value={userData.calcData?.limits?.term.increment || ''}
                          onChange={(e) => {
                            const newData = copy(userData.calcData);
                            newData.limits.term.increment = e.target.value;
                            setUserData({ ...userData, calcData: newData });
                          }}
                          SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                        >
                          {termIncrements.map((range) => <MenuItem key={range} value={`${range.replace(/[^\d.]/g, '').trim()}`}>{range}</MenuItem>)}
                        </TextField>
                      </div>
                    </div>
                    <div className="vl" />
                    <div className="vol-val">
                      <div className="input-title-and-icon">
                        <h6>Volatility percentile</h6>
                        <Tooltip
                          disableInteractive
                          title={'Edit volatility percentile here or from the volatility tab. ' +
                            'Changing it here will automatically change it on the volatility tab'}
                          PopperProps={{ className: 'bottom-arrow-tooltip longer' }}
                          placement="top"
                          arrow
                        >
                          <div className="round-icon-wrapper"><ErrorIcon /></div>
                        </Tooltip>
                      </div>
                      <TextField
                        value={userData.calcData?.limits.volatility.quantile || ''}
                        onChange={(e) => {
                          const newData = copy(userData.calcData);
                          newData.limits.volatility.quantile = e.target.value;
                          setUserData({ ...userData, calcData: newData });
                        }}
                        select
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {volatilityLabels.map((menuItem) => (
                          <MenuItem key={menuItem.label} value={menuItem.value}>
                            {menuItem.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="volatility-input">
                      <h6>Volatility range</h6>
                      <TextField
                        select
                        label="Buffer (-)"
                        value={userData.calcData?.limits.volatility.lowerBuffer || ''}
                        onChange={(e) => {
                          const newData = copy(userData.calcData);
                          newData.limits.volatility.lowerBuffer = e.target.value;
                          setUserData({ ...userData, calcData: newData });
                          setVolRangeChanged(true);
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {['-5%', '-10%', '-20%'].map((range) => (
                          <MenuItem key={range} value={range.replace('-', '').replace('%', '')}>{range}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        label="Buffer (+)"
                        value={userData.calcData?.limits?.volatility.upperBuffer || ''}
                        onChange={(e) => {
                          const newData = copy(userData.calcData);
                          newData.limits.volatility.upperBuffer = e.target.value;
                          setUserData({ ...userData, calcData: newData });
                          setVolRangeChanged(true);
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {['5%', '10%', '20%'].map((range) => (
                          <MenuItem key={range} value={range.replace('+', '').replace('%', '')}>{range}</MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        select
                        label="Increment"
                        value={userData.calcData?.limits?.volatility.increment || ''}
                        onChange={(e) => {
                          const newData = copy(userData.calcData);
                          newData.limits.volatility.increment = e.target.value;
                          setUserData({ ...userData, calcData: newData });
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true, classes: { paper: 'select-dropdown' } } }}
                      >
                        {volIncrements.map((range) => <MenuItem key={range} value={range.replace('%', '')}>{range}</MenuItem>)}
                      </TextField>
                    </div>
                  </div>
                  <div className="run-analysis-btn">
                    <Button onClick={() => { runASC820(); }} disabled={showWeightNotification || calcInProgress}>
                      {calcInProgress ? (
                        <>
                          <span className="dots-circle-spinner" />
                          Calculating...
                        </>
                      ) : (
                        <>
                          <AnalyticsOutlinedIcon />
                          {Object.keys(gridData || []).length ? 'Rerun sensitivity analysis' : 'Run sensitivity analysis'}
                        </>
                      )}
                    </Button>
                  </div>
                  {userData.gridData?.defaultScenarios && (
                    <>
                      <hr />
                      <p className="sensitivity-analysis-header">
                        Sensitivity analysis table - select volatility/term below
                        <span>
                          No need to run the calc engine, click on cells in the sensitivity analysis
                          and &apos;Calc engine outputs&apos; tables update in real-time.
                        </span>
                      </p>
                      {Object.keys(gridData || []).length &&
                        (
                          <>
                            <div className="vol-table-header">
                              {volatilityLabels.find((vol) => vol.value === userData.calcData?.limits.volatility.quantile)?.label}
                              &nbsp;sensitivity analysis
                            </div>
                            <div className="volatility-table">
                              <div className="fixed-left">
                                <div className="table-header">
                                  <div className="table-header-top-titles">
                                    <div className="term" />
                                  </div>
                                  <div className="table-sub-headers">
                                    <div className="sub-header-group term">
                                      <div className="sub-header">
                                        <h6>Term</h6>
                                        <span>(years)</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {!gridSelectionCentered && <div className="warning-message" />}
                                <div className="table-rows" ref={gridLeftScroll}>
                                  {(gridData?.terms || []).map((termVal) => (
                                    <div className="table-row-data" key={termVal}>
                                      <div className="row-data-group term">
                                        <div className={`table-cell${termVal === parseFloat(userData.calcData.limits.term.selectedTerm
                                          .replace(/[^\d.]/g, '').trim()) ? ' center' : ''}`}
                                        >
                                          {termVal.toFixed(2)}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="right-scrollable">
                                <div className="table-header" ref={gridHeaderScroll}>
                                  <div className="table-header-top-titles">
                                    {Object.keys(gridData || []).filter((volatility) => (
                                      volatility !== 'terms' && gridData?.[volatility].length
                                    )).sort((a, b) => (parseFloat(a) - parseFloat(b))).map((volatility) => (
                                      <div className={`volatility${volatility === '0' ? ' center' : ''}`} key={volatility}>
                                        <h6>
                                          {volatility !== '0' ? (volatility > 0 ? `+${volatility}%` : `${volatility}%`) :
                                            volatilityLabels.find((vol) => vol.value === userData.calcData?.limits.volatility.quantile)?.label}
                                        </h6>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="table-sub-headers">
                                    <div className="sub-header-group volatility">
                                      {Object.keys(gridData || []).filter((volatility) => (
                                        volatility !== 'terms' && gridData?.[volatility].length
                                      )).sort((a, b) => (parseFloat(a) - parseFloat(b))).map((volatility) => (
                                        <div className={`sub-header${volatility === '0' ? ' center' : ''}`} key={volatility}>
                                          <span>$</span>
                                          <span>%</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {!gridSelectionCentered && (
                                  <div className="warning-message">
                                    <p>
                                      <WarningAmberOutlinedIcon />
                                      {`Selection isn't a volatility percentile, it's ${warningCopy}.\
                                  You will not be able to 'Generate report' with this selection because it is not defensible.`}
                                    </p>
                                  </div>
                                )}
                                <div className="table-rows" ref={gridRightRef}>
                                  {(gridData?.terms || []).map((term, rowIndex) => (
                                    <div className="table-row-data" key={term}>
                                      <div className="row-data-group volatility">
                                        {Object.keys(gridData || []).filter((volatility) => (
                                          volatility !== 'terms' && gridData?.[volatility].length
                                        )).sort((a, b) => (parseFloat(a) - parseFloat(b))).map((volatility) => (
                                          <div
                                            role="button"
                                            className={`table-cell${selectedGridValue?.term === term &&
                                              selectedGridValue?.volatility === gridData?.[volatility]?.[rowIndex]?.[1]
                                                .toFixed(1) ? ' selected' : ''}\
                                                  ${gridData?.[volatility]?.[rowIndex]?.[0] ? ' can-hover' : ' no-data'}\
                                                  ${volatility === '0' ||
                                                term === parseFloat(userData.calcData.limits.term.selectedTerm
                                                  .replace(/[^\d.]/g, '').trim()) ? ' center' : ''}`}
                                            key={volatility}
                                            onClick={() => {
                                              setSelectedGridValue({
                                                term,
                                                volatility: gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1),
                                                price: gridData?.[volatility]?.[rowIndex]?.[0],
                                              });
                                              setGridValueSelected(true);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                setSelectedGridValue({
                                                  term,
                                                  volatility: gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1),
                                                  price: gridData?.[volatility]?.[rowIndex]?.[0],
                                                });
                                                setGridValueSelected(true);
                                              }
                                            }}
                                            tabIndex={gridData?.[volatility]?.[rowIndex]?.[0] ? 0 : -1}
                                          >
                                            <span>
                                              {gridData?.[volatility]?.[rowIndex]?.[0] ?
                                                `$${commaEvery3rdChar(gridData?.[volatility]?.[rowIndex]?.[0].toFixed())}` : '-'}
                                              <CheckCircleOutlineRoundedIcon />
                                            </span>
                                            <span>
                                              {gridData?.[volatility]?.[rowIndex]?.[1] ?
                                                `${gridData?.[volatility]?.[rowIndex]?.[1].toFixed(1)}%` : '-'}
                                            </span>
                                          </div>
                                        ))}
                                        {Object.keys(gridData || []).length <= 6 && <div className="table-cell filler-cell" />}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
              <Button
                className="footnote-btn"
                onClick={() => setShowFootnotes(!showFootnotes)}
              >
                <DescriptionOutlinedIcon />
                {`${showFootnotes ? 'Hide' : 'Show'} all footnotes`}
              </Button>
              <AnimateHeight duration={500} height={showFootnotes ? 'auto' : 0}>
                <div className="footnotes-area">
                  <div className="weighting-blocks">
                    <p className="block-header">
                      <span>
                        <TableChartOutlinedIcon />
                        Exhibit: Valuation methodology summary - Weighting (valuation approaches)
                      </span>
                    </p>
                    {[
                      { title: 'Backsolve method - Option pricing model (OPM)', dataName: 'backsolveWeightingApproach' },
                      { title: 'Guideline public company method (GPC)', dataName: 'gpcWeightingApproach' },
                      { title: 'Mergers and Acquisitions transaction method (M&A)', dataName: 'mnaWeightingApproach' },
                      { title: 'Preferred transaction method', dataName: 'preferredTranWeightingApproach' },
                      { title: 'Income approach - Gordon growth', dataName: 'gordonGrowthWeightingApproach' },
                      { title: 'Post-money method', dataName: 'postMoneyWeightingApproach' },
                    ].map((footnoteField) => (
                      <div key={footnoteField.dataName} className="weight-block">
                        <div className="fn-input">
                          <p>{`${footnoteField.title} appraisers commentary`}</p>
                          <TextField
                            multiline
                            value={userData.footnoteData[footnoteField.dataName]?.approach || ''}
                            onChange={(e) => setUserData({
                              ...userData, footnoteData: {
                                ...userData.footnoteData,
                                [footnoteField.dataName]: { ...userData.footnoteData[footnoteField.dataName], approach: e.target.value },
                              },
                            })}
                            onBlur={() => saveFootnotes({
                              [footnoteField.dataName]: {
                                approach: userData?.footnoteData[footnoteField.dataName]?.approach,
                              },
                            }, footnoteField.dataName)}
                            inputProps={{ maxLength: 900 }}
                          />
                          <div className="footnote-characters-left">{`${userData?.footnoteData[footnoteField.dataName]?.approach?.length}/500`}</div>
                          <Button
                            className="revert-btn"
                            onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
                          >
                            {revertFootnoteState === footnoteField.dataName ? (
                              <>
                                Reverting to default note
                                &nbsp;
                                <div className="dots-circle-spinner" />
                              </>
                            ) : (
                              <>
                                Revert to default note
                                <RefreshOutlinedIcon />
                              </>
                            )}
                          </Button>
                        </div>
                        <div className="weight-container">
                          <p>Weight</p>
                          <div>
                            <span>{userData?.footnoteData[footnoteField.dataName]?.weight}</span>
                            <h6>Weight</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="footnotes span-two">
                    <p>
                      <TableChartOutlinedIcon />
                      Exhibit: Valuation date Volatility - Industry
                    </p>
                    {[
                      { title: 'Volatility percentile - footnote', dataName: 'volPercentFn' },
                      { title: 'Transaction date volatility for backsolve - footnote', dataName: 'tdVolatilityBacksolve' },
                    ].map((footnoteField) => (
                      <div key={footnoteField.dataName}>
                        <span className={`sub-header ${footnoteField.dataName}`}>{footnoteField.title}</span>
                        <TextField
                          multiline
                          value={userData.footnoteData[footnoteField.dataName] || ''}
                          onChange={(e) => setUserData({
                            ...userData, footnoteData: {
                              ...userData.footnoteData, [footnoteField.dataName]: e.target.value,
                            },
                          })}
                          onBlur={() => saveFootnotes({
                            [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName],
                          }, footnoteField.dataName)}
                          inputProps={{ maxLength: 900 }}
                        />
                        <div className="footnote-characters-left">{`${userData?.footnoteData?.[footnoteField.dataName]?.length}/900`}</div>
                        <Button
                          className="revert-btn"
                          onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
                        >
                          {revertFootnoteState === footnoteField.dataName ? (
                            <>
                              Reverting to default note
                              &nbsp;
                              <div className="dots-circle-spinner" />
                            </>
                          ) : (
                            <>
                              Revert to default note
                              <RefreshOutlinedIcon />
                            </>
                          )}
                        </Button>
                      </div>
                    ))}
                  </div>
                  {
                    [
                      { title: 'Fair value per share', dataName: 'fairValuePerShare' },
                      { title: 'Discount for lack of marketability (DLOM)', dataName: 'dlom' },
                    ].map((footnoteField) => (
                      <div key={footnoteField.dataName} className="footnotes">
                        <p className="fn-label">
                          <TableChartOutlinedIcon />
                          {`Exhibit: ${footnoteField.title}`}
                        </p>
                        <span className={`sub-header ${footnoteField.dataName}`}>Footnote(s)</span>
                        <TextField
                          multiline
                          value={userData.footnoteData?.[footnoteField.dataName] || ''}
                          onChange={(e) => setUserData({
                            ...userData, footnoteData: {
                              ...userData.footnoteData, [footnoteField.dataName]: e.target.value,
                            },
                          })}
                          onBlur={() => saveFootnotes({
                            [footnoteField.dataName]: userData.footnoteData[footnoteField.dataName],
                          }, footnoteField.dataName)}
                          inputProps={{ maxLength: 900 }}
                        />
                        <div className="footnote-characters-left">{`${userData?.footnoteData?.[footnoteField.dataName]?.length}/900`}</div>
                        <Button
                          className="revert-btn"
                          onClick={() => revertFootnote({ [footnoteField.dataName]: null }, footnoteField.dataName)}
                        >
                          {revertFootnoteState === footnoteField.dataName ? (
                            <>
                              Reverting to default note
                              &nbsp;
                              <div className="dots-circle-spinner" />
                            </>
                          ) : (
                            <>
                              Revert to default note
                              <RefreshOutlinedIcon />
                            </>
                          )}
                        </Button>
                      </div>
                    ))}
                  <div className="methodology-summary gpc">
                    <h6>
                      <TableChartOutlinedIcon />
                      Exhibit: Value comparison
                    </h6>
                    <div className="gpc-criteria">
                      <div>
                        <p>Previous valuation - appraiser&apos;s commentary</p>
                        <TextField
                          value={userData?.footnoteData?.prevValuation || ''}
                          onChange={(e) => setUserData((prevState) => {
                            const newState = { ...prevState };
                            newState.footnoteData.prevValuation = e.target.value;
                            return newState;
                          })}
                          onBlur={() => saveFootnotes({
                            prevValuation: userData.footnoteData.prevValuation,
                          }, 'prevValuation')}
                          inputProps={{ maxLength: 900 }}
                        />
                        <div className="footnote-characters-left">{`${userData?.footnoteData?.prevValuation?.length}/900`}</div>
                        <Button
                          className="revert-btn"
                          onClick={() => revertFootnote({ prevValuation: null }, 'prevValuation')}
                        >
                          {revertFootnoteState === 'prevValuation' ? (
                            <>
                              Reverting to default note
                              &nbsp;
                              <div className="dots-circle-spinner" />
                            </>
                          ) : (
                            <>
                              Revert to default note
                              <RefreshOutlinedIcon />
                            </>
                          )}
                        </Button>
                      </div>
                      <div>
                        <p>Previous transaction - appraiser&apos;s commentary</p>
                        <TextField
                          multiline
                          value={userData?.footnoteData?.prevTransaction || ''}
                          onChange={(e) => setUserData((prevState) => {
                            const newState = { ...prevState };
                            newState.footnoteData.prevTransaction = e.target.value;
                            return newState;
                          })}
                          onBlur={() => saveFootnotes({
                            prevTransaction: userData.footnoteData.prevTransaction,
                          }, 'prevTransaction')}
                          inputProps={{ maxLength: 900 }}
                        />
                        <div className="footnote-characters-left">{`${userData?.footnoteData?.prevTransaction?.length}/900`}</div>
                        <Button
                          className="revert-btn"
                          onClick={() => revertFootnote({ prevTransaction: null }, 'prevTransaction')}
                        >
                          {revertFootnoteState === 'prevTransaction' ? (
                            <>
                              Reverting to default note
                              &nbsp;
                              <div className="dots-circle-spinner" />
                            </>
                          ) : (
                            <>
                              Revert to default note
                              <RefreshOutlinedIcon />
                            </>
                          )}
                        </Button>
                      </div>
                      <div>
                        <p>Valuation date footnote</p>
                        <TextField
                          multiline
                          value={userData?.footnoteData?.valuationDate || ''}
                          onChange={(e) => setUserData((prevState) => {
                            const newState = { ...prevState };
                            newState.footnoteData.valuationDate = e.target.value;
                            return newState;
                          })}
                          onBlur={() => saveFootnotes({
                            valuationDate: userData.footnoteData.valuationDate,
                          }, 'valuationDate')}
                          inputProps={{ maxLength: 900 }}
                        />
                        <div className="footnote-characters-left">{`${userData?.footnoteData?.valuationDate?.length}/900`}</div>
                        <Button
                          className="revert-btn"
                          onClick={() => revertFootnote({ valuationDate: null }, 'valuationDate')}
                        >
                          {revertFootnoteState === 'valuationDate' ? (
                            <>
                              Reverting to default note
                              &nbsp;
                              <div className="dots-circle-spinner" />
                            </>
                          ) : (
                            <>
                              Revert to default note
                              <RefreshOutlinedIcon />
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimateHeight>
              <hr />
              <div className="calc-engine-outputs">
                <div className="calc-engine-outputs-header">
                  <h6>
                    <InputIcon />
                    Calc engine outputs
                  </h6>
                  {userData?.gridData?.defaultScenarios ? (
                    <>
                      <p>
                        When you think you&apos;re ready, generate a report from here or from the report tab and view all exhibits.
                        <br />
                        Don&apos;t worry, you can generate as many reports as you wish, and then choose which report to send to your client.
                      </p>
                      <Button
                        disabled={!gridSelectionCentered}
                        onClick={() => {
                          setTabSelected('report');
                          setGenerateNewReport({ type: 'draft' });
                        }}
                      >
                        <MenuBookOutlinedIcon />
                        Generate draft report
                      </Button>
                    </>
                  ) : (
                    <p>
                      Play around with the above inputs, and weight methods then run the calc engine to see calculated, weighted holdings below.
                      <br />
                      You can run the calc engine as many times as you want.
                    </p>
                  )}
                </div>
                {!userData.gridData?.defaultScenarios ? (
                  <div className="no-outputs-to-show">
                    {calcInProgress ? <span className="dots-circle-spinner" /> : 'Calc engine outputs will show here'}
                  </div>
                ) : (
                  <>
                    <div className="outputs-values-row">
                      <div className="value-block large">
                        <span>
                          {userData.gridData?.defaultScenarios?.backsolve?.gsEquityValue ?
                            `$${commaEvery3rdChar(parseFloat(userData.gridData.defaultScenarios.backsolve.gsEquityValue).toFixed(0))}` : '-'}
                        </span>
                        <span>Backsolve concluded equity</span>
                      </div>
                      <div className="vl" />
                      <div className="value-block large">
                        <span>
                          {userData.gridData?.defaultScenarios?.backsolve?.equityValue ?
                            `$${commaEvery3rdChar(parseFloat(userData.gridData.defaultScenarios.backsolve.equityValue).toFixed(0))}` : '-'}
                        </span>
                        <span>
                          Backsolve market
                          <br />
                          adjusted concluded equity
                        </span>
                      </div>
                      <div className="value-block large">
                        <span>
                          {userData.gridData?.defaultScenarios?.pubco?.equityValue ?
                            `$${commaEvery3rdChar(parseFloat(userData.gridData.defaultScenarios.pubco.equityValue).toFixed(0))}` : '-'}
                        </span>
                        <span>GPC concluded equity</span>
                      </div>
                      <div className="vl" />
                      <div className="value-block">
                        <span>
                          {userData.gridData?.defaultScenarios?.methodsDifference ?
                            `${userData.gridData.defaultScenarios.methodsDifference.toFixed(2)}%` : '-'}
                        </span>
                        <span>Difference between methods</span>
                      </div>
                    </div>
                    <div className="outputs-values-row">
                      <div className="value-block">
                        <span>
                          {userData.gridData?.defaultScenarios?.volatility ? `${userData.gridData.defaultScenarios.volatility}%` : '-'}
                        </span>
                        <span>
                          Volatility from
                          <br />
                          sensitivity analysis
                        </span>
                      </div>
                      <div className="value-block">
                        <span>
                          {userData.gridData?.defaultScenarios?.term ?
                            `${userData.gridData.defaultScenarios.term.toFixed(2)} year` +
                            `${parseFloat(userData.gridData.defaultScenarios.term) > 1 ? 's' : ''}` : '-'}
                        </span>
                        <span>
                          Term from
                          <br />
                          sensitivity analysis
                        </span>
                      </div>
                      <div className="vl" />
                      <div className="value-block">
                        <span>
                          {userData.gridData?.defaultScenarios?.backsolve?.gsVolatility ?
                            `${userData.gridData.defaultScenarios.backsolve.gsVolatility.toFixed(2)}%` : '-'}
                        </span>
                        <span>
                          Transaction date
                          <br />
                          volatility
                        </span>
                      </div>
                      <div className="value-block">
                        <span>
                          {userData.gridData?.defaultScenarios?.backsolve?.gsTerm ?
                            `${userData.gridData.defaultScenarios.backsolve.gsTerm.toFixed(2)} year` +
                            `${userData.gridData.defaultScenarios.backsolve.gsTerm > 1 ? 's' : ''}` : '-'}
                        </span>
                        <span>
                          Transaction date
                          <br />
                          time to exit (term)
                        </span>
                      </div>
                      <div className="value-block">
                        <span>
                          {userData.gridData?.defaultScenarios?.backsolve?.gsRfr ?
                            `${(userData.gridData.defaultScenarios.backsolve.gsRfr * 100).toFixed(2)}%` : '-'}
                        </span>
                        <span>
                          Transaction date
                          <br />
                          risk-free rate
                        </span>
                      </div>
                    </div>
                    <div className="tables-wrapper">
                      <div className={`methods-table${calcData?.mna ? 'three-tables' : ''}`}>
                        <div className="backsolve-table">
                          <div className="table-header">
                            <div className="table-header-top-titles">
                              <div className="backsolve"><h6>Weighted Backsolve</h6></div>
                            </div>
                            <div className="table-sub-headers">
                              <div className="sub-header-group backsolve">
                                <div className="sub-header">
                                  <h6>Class</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Per share</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Aggregate value</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-rows">
                            {Object.keys(
                              calcData?.backsolve?.perShareConclusion['Ownership Class'] || [],
                            ).map((className, rowIndex) => (
                              <div className="table-row-data" key={className}>
                                <div className="row-data-group backsolve">
                                  <div className="table-cell">
                                    {calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex].length >= 25 ? (
                                      <Tooltip title={calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex]} placement="top">
                                        <span>
                                          {`${calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex].slice(0, 25).trim()}...`}
                                        </span>
                                      </Tooltip>
                                    ) : calcData?.backsolve?.perShareConclusion['Ownership Class']?.[rowIndex]}
                                  </div>
                                  <div className="table-cell">
                                    {`$${calcData?.backsolve?.perShareConclusion['Allocated Per Share Value']?.[rowIndex].toFixed(4)}`}
                                  </div>
                                  <div className="table-cell">
                                    {`$${commaEvery3rdChar(
                                      calcData?.backsolve?.perShareConclusion['Aggregate Value']?.[rowIndex].toFixed(0),
                                    )}`}
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="total-row">
                              <div className="table-cell" />
                              <div className="table-cell">
                                {`$${commaEvery3rdChar(calcData?.backsolve?.backsolveTotalValue?.toFixed(0) || 0)}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        {calcData?.pubco && (
                          <div className="pubCo-table">
                            <div className="table-header">
                              <div className="table-header-top-titles">
                                <div className="pubCo"><h6>Weighted PubCo</h6></div>
                              </div>
                              <div className="table-sub-headers">
                                <div className="sub-header-group pubCo">
                                  <div className="sub-header">
                                    <h6>Class</h6>
                                  </div>
                                  <div className="sub-header">
                                    <h6>Per share</h6>
                                  </div>
                                  <div className="sub-header">
                                    <h6>Aggregate value</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="table-rows">
                              {Object.keys(calcData?.pubco?.perShareConclusion['Ownership Class'] || []).map((className, rowIndex) => (
                                <div className="table-row-data" key={className}>
                                  <div className="row-data-group pubCo">
                                    <div className="table-cell">
                                      {calcData?.pubco?.perShareConclusion['Ownership Class']?.[rowIndex]}
                                    </div>
                                    <div className="table-cell">
                                      {`$${calcData?.pubco?.perShareConclusion['Allocated Per Share Value']?.[rowIndex].toFixed(4)}`}
                                    </div>
                                    <div className="table-cell">
                                      {`$${commaEvery3rdChar(
                                        calcData?.pubco?.perShareConclusion['Aggregate Value']?.[rowIndex].toFixed(0),
                                      )}`}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="total-row">
                              <div className="table-cell" />
                              <div className="table-cell">
                                {`$${commaEvery3rdChar(calcData?.pubco?.pubcoTotalValue?.toFixed(0) || 0)}`}
                              </div>
                            </div>
                          </div>
                        )}
                        {calcData?.mna && (
                          <div className="mAndA-table">
                            <div className="table-header">
                              <div className="table-header-top-titles">
                                <div className="mAndA"><h6>M&A method</h6></div>
                              </div>
                              <div className="table-sub-headers">
                                <div className="sub-header-group mAndA">
                                  <div className="sub-header">
                                    <h6>Class</h6>
                                  </div>
                                  <div className="sub-header">
                                    <h6>Per share</h6>
                                  </div>
                                  <div className="sub-header">
                                    <h6>Aggregate value</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="table-rows">
                              {Object.keys(calcData?.mna?.['Ownership Class'] || []).map((className, rowIndex) => (
                                <div className="table-row-data" key={className}>
                                  <div className="row-data-group mAndA">
                                    <div className="table-cell">
                                      {calcData?.mna?.['Ownership Class']?.[rowIndex]}
                                    </div>
                                    <div className="table-cell">
                                      {`$${calcData?.mna?.['Allocated Per Share Value']?.[rowIndex].toFixed(4)}`}
                                    </div>
                                    <div className="table-cell">
                                      {`$${commaEvery3rdChar(calcData?.mna?.['Aggregate Value']?.[rowIndex].toFixed(0))}`}
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="total-row">
                                <div className="table-cell" />
                                <div className="table-cell">Data</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {calcData?.concluded ? (
                        <div className="concluded-table">
                          <div className="table-header">
                            <div className="table-header-top-titles">
                              <div className="concluded method-title">
                                <h6>Weighted holding values</h6>
                                <p>
                                  {determineMethod()}
                                </p>
                              </div>
                            </div>
                            <div className="table-sub-headers">
                              <div className="sub-header-group concluded">
                                <div className="sub-header">
                                  <h6>Class of stock</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Per share</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Total shares</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Total value</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Shares held</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Holding value</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Assumed holding value</h6>
                                </div>
                                <div className="sub-header">
                                  <h6>Difference</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-rows">
                            {Object.keys(calcData?.concluded?.object?.['Class of stock'] || []).map((className, rowIndex) => (
                              <div className="table-row-data" key={className}>
                                <div className="row-data-group concluded">
                                  <div className="table-cell">
                                    {calcData.concluded.object['Class of stock'][rowIndex]}
                                  </div>
                                  <div className="table-cell">
                                    {`$${calcData.concluded.object['Per share'][rowIndex].toFixed(2)}`}
                                  </div>
                                  <div className="table-cell">
                                    {`${commaEvery3rdChar(calcData.concluded.object['Total shares'][rowIndex])}`}
                                  </div>
                                  <div className="table-cell">
                                    {`$${commaEvery3rdChar(calcData.concluded.object['Total Value'][rowIndex].toFixed(0))}`}
                                  </div>
                                  <div className="table-cell">
                                    {`${commaEvery3rdChar(calcData.concluded.object['Shares held'][rowIndex])}`}
                                  </div>
                                  <div className="table-cell">
                                    {calcData.concluded.object['Holding value'][rowIndex] ?
                                      `$${commaEvery3rdChar(calcData.concluded.object['Holding value'][rowIndex].toFixed(0))}` : 'N/A'}
                                  </div>
                                  <div className="table-cell">
                                    {calcData.concluded.object['Assumed holding value'][rowIndex] ?
                                      `$${commaEvery3rdChar(
                                        calcData.concluded.object['Assumed holding value'][rowIndex].toFixed(0),
                                      )}` : 'N/A'}
                                  </div>
                                  <div className="table-cell">
                                    {`${(calcData.concluded.object.Difference[rowIndex] * 100).toFixed(2)}%`}
                                  </div>
                                  <div className="table-cell" />
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="total-row">
                            <div className="table-cell" />
                            <div className="table-cell" />
                            <div className="table-cell">
                              {calcData ? `${commaEvery3rdChar(calcData.concluded.totalShares?.toFixed(0) || 0)}` : ''}
                            </div>
                            <div className="table-cell">
                              {calcData ? `$${commaEvery3rdChar(calcData.concluded.totalValue?.toFixed(0) || 0)}` : ''}
                            </div>
                            <div className="table-cell">
                              {calcData ? `${commaEvery3rdChar(calcData.concluded.totalSharesHeld?.toFixed(0) || 0)}` : ''}
                            </div>
                            <div className="table-cell">
                              {calcData ? `$${commaEvery3rdChar(calcData.concluded.totalHoldingValue?.toFixed(0) || 0)}` : ''}
                            </div>
                            <div className="table-cell">
                              {calcData ? `$${commaEvery3rdChar(calcData.concluded.totalAssumedHoldingValue?.toFixed(0) || 0)}` : ''}
                            </div>
                            <div className="table-cell">
                              {calcData ? `${(calcData.concluded.difference * 100).toFixed(2)}%` : ''}
                            </div>
                            <div className="table-cell" />
                          </div>
                          <div className="final-row">
                            <div className="table-cell">Fund ownership:</div>
                            <div className="table-cell">{calcData ? `${(calcData.concluded.fundOwnership * 100).toFixed(2)}%` : ''}</div>
                            <div className="table-cell" />
                          </div>
                        </div>
                      ) : (
                        <div>
                          data missing
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="calc-footer" />
          </div>
        )}
    </div>
  );
}

Calculations.propTypes = {
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  setTabSelected: PropTypes.func.isRequired,
  setGenerateNewReport: PropTypes.func.isRequired,
  selectedGridValue: PropTypes.object.isRequired,
  setSelectedGridValue: PropTypes.func.isRequired,
};
