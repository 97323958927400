import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import Menu from '@mui/material/Menu';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './FilterMenus.scss';

export default function FilterMenus({
  tabSelected,
  growthFilterOptions,
  multiplesFilterOptions,
  yearsFilterOptions,
  fiscalPeriodFilterOptions,
  filters,
  setFilters,
  descriptionsToShow,
  setDescriptionsToShow,
}) {
  // Filter Menus
  const [growthAndMarginsAnchorEl, setGrowthAndMarginsAnchorEl] = useState(null);
  const [growthAndMarginsMenuIsOpen, setGrowthAndMarginsMenuIsOpen] = useState(false);
  const [multiplesMenuAnchorEl, setMultiplesMenuAnchorEl] = useState(null);
  const [multiplesMenuIsOpen, setMultiplesMenuIsOpen] = useState(false);
  const [yearsMenuAnchorEl, setYearsMenuAnchorEl] = useState(null);
  const [yearsMenuIsOpen, setYearsMenuIsOpen] = useState(false);
  const [fiscalYearMenuAnchorEl, setFiscalYearMenuAnchorEl] = useState(null);
  const [fiscalYearMenuIsOpen, setFiscalYearMenuIsOpen] = useState(false);
  const [yearsSliderValue, setYearsSliderValue] = useState([-10, 0]);
  const [yearsIncrementValue, setYearsIncrementValue] = useState(0.25);

  useEffect(() => {
    let newYearsFilterValues = [...yearsFilterOptions];
    newYearsFilterValues = newYearsFilterValues
      .filter((_, i) => !((i + 1) % (yearsIncrementValue / 0.25)))
      .filter((val) => parseFloat(val) <= Math.abs(yearsSliderValue[0]) && parseFloat(val) >= Math.abs(yearsSliderValue[1]));
    setFilters({ ...filters, yearsFilterValues: newYearsFilterValues });
  }, [yearsSliderValue, yearsIncrementValue]);

  return (
    <div className="FilterMenus">
      <div className="filter-options">
        <span>Filter comp list table:</span>
        {tabSelected === 'comps' && (
          <>
            <ToggleButton
              value={filters.showCompanyInfo}
              selected={filters.showCompanyInfo}
              onChange={() => setFilters({ ...filters, showCompanyInfo: !filters.showCompanyInfo })}
            >
              {filters.showCompanyInfo && <CheckCircleOutlineIcon />}
              Company info
            </ToggleButton>
            <ToggleButton
              value={filters.showRevenue}
              selected={filters.showRevenue}
              onChange={() => setFilters({ ...filters, showRevenue: !filters.showRevenue })}
            >
              {filters.showRevenue && <CheckCircleOutlineIcon />}
              Revenue
            </ToggleButton>
            <ToggleButton
              value={filters.showEBITDA}
              selected={filters.showEBITDA}
              onChange={() => setFilters({ ...filters, showEBITDA: !filters.showEBITDA })}
            >
              {filters.showEBITDA && <CheckCircleOutlineIcon />}
              EBITDA
            </ToggleButton>
            <ToggleButton
              value={filters.showGrossProfit}
              selected={filters.showGrossProfit}
              onChange={() => setFilters({ ...filters, showGrossProfit: !filters.showGrossProfit })}
            >
              {filters.showGrossProfit && <CheckCircleOutlineIcon />}
              Gross Profit
            </ToggleButton>
            <ToggleButton
              value={filters.showMarketCap}
              selected={filters.showMarketCap}
              onChange={() => setFilters({ ...filters, showMarketCap: !filters.showMarketCap })}
            >
              {filters.showMarketCap && <CheckCircleOutlineIcon />}
              Market Capitalization
            </ToggleButton>
            <Button
              className={filters.growthFilterValues.length ? 'selected' : ''}
              onClick={(e) => {
                setGrowthAndMarginsAnchorEl(e.currentTarget);
                setGrowthAndMarginsMenuIsOpen(true);
              }}
            >
              {!!filters.growthFilterValues.length && <CheckCircleOutlineIcon />}
              Growth & Margins
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              className="comp-filter-menu"
              anchorEl={growthAndMarginsAnchorEl}
              open={growthAndMarginsMenuIsOpen}
              onClose={() => {
                setGrowthAndMarginsAnchorEl(null);
                setGrowthAndMarginsMenuIsOpen(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setFilters({
                    ...filters,
                    growthFilterValues: growthFilterOptions.length === filters.growthFilterValues.length ? [] : growthFilterOptions,
                  });
                }}
              >
                <Checkbox
                  checked={growthFilterOptions.length === filters.growthFilterValues.length}
                  icon={<div className="unchecked-icon" />}
                  checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                />
                Select All
              </MenuItem>
              <hr />
              {growthFilterOptions.map((itemLabel) => (
                <MenuItem
                  key={itemLabel}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      growthFilterValues: filters.growthFilterValues.includes(itemLabel) ?
                        filters.growthFilterValues.filter((option) => option !== itemLabel) :
                        [...filters.growthFilterValues, itemLabel],
                    });
                  }}
                >
                  <Checkbox
                    checked={filters.growthFilterValues.includes(itemLabel)}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                  {itemLabel}
                </MenuItem>
              ))}
            </Menu>
            <Button
              className={filters.multiplesFilterValues.length ? 'selected' : ''}
              onClick={(e) => {
                setMultiplesMenuAnchorEl(e.currentTarget);
                setMultiplesMenuIsOpen(true);
              }}
            >
              {!!filters.multiplesFilterValues.length && <CheckCircleOutlineIcon />}
              Multiples
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              className="comp-filter-menu"
              anchorEl={multiplesMenuAnchorEl}
              open={multiplesMenuIsOpen}
              onClose={() => {
                setMultiplesMenuAnchorEl(null);
                setMultiplesMenuIsOpen(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setFilters({
                    ...filters,
                    multiplesFilterValues: multiplesFilterOptions.length === filters.multiplesFilterValues.length ? [] : multiplesFilterOptions,
                  });
                }}
              >
                <Checkbox
                  checked={multiplesFilterOptions.length === filters.multiplesFilterValues.length}
                  icon={<div className="unchecked-icon" />}
                  checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                />
                Select All
              </MenuItem>
              <hr />
              {multiplesFilterOptions.map((itemLabel) => (
                <MenuItem
                  key={itemLabel}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      multiplesFilterValues: filters.multiplesFilterValues.includes(itemLabel) ?
                        filters.multiplesFilterValues.filter((option) => option !== itemLabel) :
                        [...filters.multiplesFilterValues, itemLabel],
                    });
                  }}
                >
                  <Checkbox
                    checked={filters.multiplesFilterValues.includes(itemLabel)}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                  {itemLabel}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {tabSelected === 'industry' && (
          <>
            <ToggleButton
              value={filters.showRevenue}
              selected={filters.showRevenue}
              onChange={() => setFilters({ ...filters, showRevenue: !filters.showRevenue })}
            >
              {filters.showRevenue && <CheckCircleOutlineIcon />}
              Revenue
            </ToggleButton>
            <ToggleButton
              value={filters.showVolatilityValDate}
              selected={filters.showVolatilityValDate}
              onChange={() => setFilters({ ...filters, showVolatilityValDate: !filters.showVolatilityValDate })}
            >
              {filters.showVolatilityValDate && <CheckCircleOutlineIcon />}
              Volatility - valuation date
            </ToggleButton>
            <ToggleButton
              value={filters.showVolatilityTrDate}
              selected={filters.showVolatilityTrDate}
              onChange={() => setFilters({ ...filters, showVolatilityTrDate: !filters.showVolatilityTrDate })}
            >
              {filters.showVolatilityTrDate && <CheckCircleOutlineIcon />}
              Volatility - transaction date
            </ToggleButton>
            <ToggleButton
              value={filters.showMarketCap}
              selected={filters.showMarketCap}
              onChange={() => setFilters({ ...filters, showMarketCap: !filters.showMarketCap })}
            >
              {filters.showMarketCap && <CheckCircleOutlineIcon />}
              Market capitalization
            </ToggleButton>
            <Button
              className={filters.yearsFilterValues.length !== yearsFilterOptions.length ? 'selected' : ''}
              onClick={(e) => {
                setYearsMenuAnchorEl(e.currentTarget);
                setYearsMenuIsOpen(true);
              }}
            >
              {filters.yearsFilterValues.length !== yearsFilterOptions.length && <CheckCircleOutlineIcon />}
              Years
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              style={{ position: 'absolute' }}
              className="years-filter"
              anchorEl={yearsMenuAnchorEl}
              open={yearsMenuIsOpen}
              onClose={() => {
                setYearsMenuAnchorEl(null);
                setYearsMenuIsOpen(false);
              }}
            >
              <div className="years-filter-content">
                <div>
                  <h6>Show range of years:</h6>
                  <Slider
                    orientation="vertical"
                    value={yearsSliderValue}
                    onChange={(_, newValue, activeThumb) => {
                      if (activeThumb === 0) {
                        setYearsSliderValue([Math.min(newValue[0], yearsSliderValue[1] - 1), yearsSliderValue[1]]);
                      } else {
                        setYearsSliderValue([yearsSliderValue[0], Math.max(newValue[1], yearsSliderValue[0] + 1)]);
                      }
                    }}
                    step={null}
                    marks={[...Array(11)].map((_, i) => ({ value: -i, label: i }))}
                    min={-11}
                    max={1}
                    scale={(x) => -x}
                    disableSwap
                  />
                </div>
                <div className="vr" />
                <div>
                  <h6>In increments of:</h6>
                  <RadioGroup
                    value={yearsIncrementValue}
                    onChange={(e) => setYearsIncrementValue(e.target.value)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={0.25}
                      label="0.25 year increment"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={0.5}
                      label="0.5 year increment"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={1}
                      label="1 year increment"
                    />
                  </RadioGroup>
                </div>
              </div>
            </Menu>
          </>
        )}
        {tabSelected === 'comps' && (
          <>
            <Button
              className={filters.fiscalPeriodFilterValues.length ? 'selected' : ''}
              onClick={(e) => {
                setFiscalYearMenuAnchorEl(e.currentTarget);
                setFiscalYearMenuIsOpen(true);
              }}
            >
              {!!filters.fiscalPeriodFilterValues.length && <CheckCircleOutlineIcon />}
              Fiscal Period
              <ArrowDropDownIcon />
            </Button>
            <Menu
              disableScrollLock
              className="comp-filter-menu"
              anchorEl={fiscalYearMenuAnchorEl}
              open={fiscalYearMenuIsOpen}
              onClose={() => {
                setFiscalYearMenuAnchorEl(null);
                setFiscalYearMenuIsOpen(false);
              }}
            >
              <MenuItem
                onClick={() => {
                  setFilters({
                    ...filters,
                    fiscalPeriodFilterValues: fiscalPeriodFilterOptions.length === filters.fiscalPeriodFilterValues.length ? [] :
                      fiscalPeriodFilterOptions,
                  });
                }}
              >
                <Checkbox
                  checked={filters.fiscalPeriodFilterValues.length === fiscalPeriodFilterOptions.length}
                  icon={<div className="unchecked-icon" />}
                  checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                />
                Select All
              </MenuItem>
              <hr />
              {fiscalPeriodFilterOptions.map((itemLabel) => (
                <MenuItem
                  key={itemLabel}
                  onClick={() => {
                    setFilters({
                      ...filters,
                      fiscalPeriodFilterValues: filters.fiscalPeriodFilterValues.includes(itemLabel) ?
                        filters.fiscalPeriodFilterValues.filter((option) => option !== itemLabel) :
                        [...filters.fiscalPeriodFilterValues, itemLabel],
                    });
                  }}
                >
                  <Checkbox
                    checked={filters.fiscalPeriodFilterValues.includes(itemLabel)}
                    icon={<div className="unchecked-icon" />}
                    checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                  />
                  {itemLabel}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
      <p className="helper-text">
        Select the table categories you want to see.&nbsp;
        <Button
          onClick={() => {
            setFilters({
              ...filters,
              ...tabSelected === 'comps' && {
                showCompanyInfo: false,
                showEBITDA: false,
                yearsFilterValues: [],
                fiscalPeriodFilterValues: [],
              },
              ...tabSelected === 'industry' && {
                showVolatilityValDate: false,
                showVolatilityTrDate: false,
                showGrossProfit: false,
                showMarketCap: false,
                growthFilterValues: [],
                multiplesFilterValues: [],
              },
              showRevenue: false,
              showMarketCap: false,
            });
          }}
        >
          Clear filters
        </Button>
        &nbsp;to see all tables.&nbsp;
        <Button onClick={() => setDescriptionsToShow(
          descriptionsToShow.map(() => descriptionsToShow.every((descriptionToShow) => descriptionToShow === 'short') ? '' : 'short'),
        )}
        >
          {descriptionsToShow.every((descriptionToShow) => descriptionToShow === 'short') ? 'Hide ' : 'View '}
          all short descriptions
        </Button>
        &nbsp;or&nbsp;
        <Button onClick={() => setDescriptionsToShow(
          descriptionsToShow.map(() => descriptionsToShow.every((descriptionToShow) => descriptionToShow === 'long') ? '' : 'long'),
        )}
        >
          {descriptionsToShow.every((descriptionToShow) => descriptionToShow === 'long') ? 'Hide ' : 'View '}
          all long descriptions
        </Button>
        &nbsp;to open all descriptions at the same time.
      </p>
    </div>
  );
}

FilterMenus.propTypes = {
  tabSelected: PropTypes.string.isRequired,
  growthFilterOptions: PropTypes.array.isRequired,
  multiplesFilterOptions: PropTypes.array.isRequired,
  yearsFilterOptions: PropTypes.array.isRequired,
  fiscalPeriodFilterOptions: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  descriptionsToShow: PropTypes.array.isRequired,
  setDescriptionsToShow: PropTypes.func.isRequired,
};
