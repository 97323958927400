import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, OutlinedInput } from '@mui/material';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { commaEvery3rdChar, copy, onlyDates } from '../../../utils';

import './HoverInput.scss';

export default function HoverInput({
  setInputValues,
  inputValues,
  label,
  setUpdateHoverInputs,
  dbKey,
  add$,
  addCommas,
  isDate,
}) {
  const [labelHover, setLabelHover] = useState(false);
  const [isEditActive, setEditActive] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const formatInputDate = (input) => {
    const numericValue = input.replace(/\D/g, '');
    if (moment(numericValue, 'MMDDYYYY')) return moment(numericValue, 'MMDDYYYY').format('MM/DD/YY');
    if (moment(numericValue, 'YYYYMMDD')) return moment(numericValue, 'YYYYMMDD').format('MM/DD/YY');
    return moment(numericValue, 'MMDDYY').format('MM/DD/YY');
    // more cases can be added
  };

  const handleBlur = () => {
    const formattedDate = formatInputDate(isDate ? value : '');
    if (value.length > 1 && formattedDate === 'Invalid date' && isDate) {
      setError(true);
    } else if (formattedDate !== 'Invalid date' && formattedDate !== inputValues[dbKey] && value.length > 5) {
      const valueCopy = { ...inputValues };
      valueCopy[dbKey] = formattedDate;
      setInputValues(valueCopy);
      setEditActive(false);
      setError(false);
      setValue(formattedDate);
      setUpdateHoverInputs(true);
    } else if (add$ || addCommas) {
      setInputValues(value);
      setEditActive(false);
      setError(false);
      setUpdateHoverInputs(true);
    } else {
      setValue('');
      let copyIv = copy(inputValues);
      if (isDate) {
        copyIv[dbKey] = '';
      } else copyIv = '';
      setInputValues(copyIv);
      setUpdateHoverInputs(true);
      setEditActive(false);
    }
  };

  const valueToDisplay = (val) => {
    let v = val;
    // const val = (inputTracking ? vals[dataName]?.val : vals?.[dataName]);
    // if (val && addPerc) return `% ${val}`;
    // if (val && addMonths) return val === '1' ? `${val} month` : `${val} months`;
    // if (dataName === 'companyIndustry') return (val ? val[0] : '');
    if (val && add$) v = `$${commaEvery3rdChar(v)}`;
    if (val && addCommas && !add$) v = commaEvery3rdChar(v);
    setValue(v || '');
    setInputValues(v);
  };

  useEffect(() => {
    if (isDate && inputValues && inputValues[dbKey]) {
      setValue(formatInputDate(inputValues[dbKey]));
    } else if (inputValues && typeof inputValues === 'string') {
      valueToDisplay(inputValues);
    }
  }, [inputValues]);

  return (
    <div className="hover-item">
      <div
        className="hover-label"
        onMouseOver={() => { if (!labelHover) setLabelHover(true); }}
        onMouseLeave={() => setLabelHover(false)}
        onFocus={() => setLabelHover(true)}
      >
        <span>
          <span className="label-text">
            {label}
            :
          </span>
          {isEditActive ? (
            <span className="hover-input">
              <OutlinedInput
                type="text"
                value={value}
                onChange={(e) => isDate ? setValue(onlyDates(e.target.value)) : valueToDisplay(e.target.value)}
                onFocus={() => setError(false)}
                onBlur={handleBlur}
                inputProps={{ maxLength: 10 }}
                autoFocus
                onFocusCapture={(e) => e.target.select(e.target.value.length)}
                error={error}
                onKeyDown={(e) => (e.key === 'Enter' && handleBlur(e.target.value))}
              />
              <IconButton
                size="small"
                onClick={() => {
                  if (error) setValue('');
                  const inputValueCopy = { ...inputValues };
                  inputValueCopy[dbKey] = '';
                  setInputValues(inputValueCopy);
                  setEditActive(false);
                  setUpdateHoverInputs(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          ) : (
            <>
              <span
                className="input-value"
                onFocus={() => setLabelHover(true)}

              >
                {value || ''}
              </span>
              <div className="edit-btn-wrapper">
                <IconButton
                  className="edit-icon-btn"
                  onFocus={() => setLabelHover(true)}
                  onKeyDown={(e) => e.key === 'Tab' && setLabelHover(false)}
                  onClick={() => setEditActive(true)}
                >
                  <EditIcon style={{ display: labelHover ? 'block' : 'none' }} />
                </IconButton>
              </div>
            </>
          )}
        </span>
      </div>
    </div>
  );
}

HoverInput.propTypes = {
  setInputValues: PropTypes.func.isRequired,
  inputValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  label: PropTypes.string.isRequired,
  setUpdateHoverInputs: PropTypes.func.isRequired,
  dbKey: PropTypes.string,
  add$: PropTypes.bool,
  addCommas: PropTypes.bool,
  isDate: PropTypes.bool,
};

HoverInput.defaultProps = {
  inputValues: '' || {},
  dbKey: '',
  add$: false,
  addCommas: false,
  isDate: false,
};
