/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// TODO Handle accessibility for mouse over events
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import getCompsColumns from './compsTableColumns';

import './CompTableRow.scss';

export default function CompTableRow({
  compData,
  rowIndex,
  tabSelected,
  setShowConfirmationModal,
  filters,
  reSummarize,
  descriptionsToShow,
  setDescriptionsToShow,
  descriptionsRef,
  checkboxesRef,
  subCompScroll,
  overlayScrollbarRef,
  overlayScroll,
}) {
  const [shortDescBtnFocused, setShortDescBtnFocused] = useState(-1);
  const [shortDescBtnHovered, setShortDescBtnHovered] = useState(-1);
  const [longDescBtnFocused, setLongDescBtnFocused] = useState(-1);
  const [longDescBtnHovered, setLongDescBtnHovered] = useState(-1);

  const [checkboxHovered, setCheckboxHovered] = useState(-1);

  const [rowHovered, setRowHovered] = useState(-1);

  const rowClassName = (index) => {
    if (overlayScroll) return 'comp-table-row-data overlay-scroll sub-company odd';
    if (index === 0) return 'comp-table-row-data sub-company odd';
    let className = 'comp-table-row-data';
    if (rowHovered === index) className += ' row-hovered';
    if ((tabSelected === 'comps' && !compData[rowIndex].Multiples) ||
      (tabSelected === 'industry' && !compData[rowIndex].Active)) className += ' red-inactive-row';
    if (tabSelected === 'comps' && compData[rowIndex].Multiples && !compData[rowIndex].Active) className += ' yellow-multiples-disabled-row';
    if ((index + 1) % 2 === 0) className += ' even';
    else className += ' odd';
    return className;
  };

  return (
    <div className="CompTableRow">
      <div
        className={rowClassName(rowIndex)}
        onMouseOver={() => { if (rowHovered !== rowIndex) setRowHovered(rowIndex); }}
        onMouseLeave={() => setRowHovered(-1)}
      >
        <div className={`fixed-left ${rowClassName(rowIndex)}`}>
          <div className="row-data-group comp-list">
            {rowIndex !== 0 ? (
              <>
                <div
                  className="table-cell"
                  onMouseOver={() => { if (checkboxHovered !== rowIndex) setCheckboxHovered(rowIndex); }}
                  onMouseLeave={() => setCheckboxHovered(-1)}
                >
                  <Tooltip
                    PopperProps={{ className: 'bottom-arrow-tooltip select-deselect' }}
                    title={
                      compData[rowIndex].Active && compData[rowIndex].Multiples ?
                        (
                          <span>
                            Click to remove
                          </span>
                        ) : tabSelected === 'comps' && !compData[rowIndex].Active ? (
                          <span>
                            Automatically removed when
                            <br />
                            removed from volatility tab.
                          </span>
                        ) : (
                          <span>
                            Click to include
                          </span>
                        )
                    }
                    enterDelay={500}
                    enterNextDelay={1500}
                    placement="top"
                    arrow
                  >
                    <Checkbox
                      inputRef={rowIndex !== 0 ? checkboxesRef.current[rowIndex] : null}
                      className={tabSelected === 'comps' && compData[rowIndex].Multiples && !compData[rowIndex].Active ? 'yellow' : ''}
                      checked={tabSelected === 'comps' ? !!compData[rowIndex].Active && !!compData[rowIndex].Multiples : !!compData[rowIndex].Active}
                      onChange={() => {
                        if (tabSelected === 'comps' && compData[rowIndex].Multiples && !compData[rowIndex].Active) {
                          setShowConfirmationModal(rowIndex);
                          return;
                        }
                        reSummarize(rowIndex, tabSelected === 'industry' ? 'active' : 'multiple');
                      }}
                      icon={<div className="remove-check-icon"><RemoveRoundedIcon /></div>}
                      checkedIcon={<div className="checked-icon"><CheckRoundedIcon /></div>}
                    />
                  </Tooltip>
                </div>
                <div className="table-cell">
                  {compData[rowIndex].Name.replace(/ *\([^)]*\) */g, '').trim().length > 41 ? (
                    <Tooltip title={compData[rowIndex].Name.replace(/ *\([^)]*\) */g, '').trim()} placement="top">
                      <span>{`${compData[rowIndex].Name.slice(0, 41).trim()}...`}</span>
                    </Tooltip>
                  ) : compData[rowIndex].Name.replace(/ *\([^)]*\) */g, '').trim()}
                </div>
                <div className="table-cell">
                  {compData[rowIndex]?.Outlier ? (
                    <IconButton
                      className="outlier-flag-icon is-outlier"
                      onClick={() => { reSummarize(rowIndex, 'outlier'); }}
                    >
                      <FlagIcon />
                    </IconButton>
                  ) : (
                    <Tooltip
                      disableInteractive
                      title="Flag as outlier"
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      enterDelay={500}
                      enterNextDelay={1500}
                      arrow
                    >
                      <IconButton
                        className={`outlier-flag-icon${rowHovered !== rowIndex ? ' hidden' : ''}`}
                        onClick={() => { reSummarize(rowIndex, 'outlier'); }}
                      >
                        <FlagOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="table-cell" />
                <div className="table-cell">{compData[rowIndex].Name}</div>
                <div className="table-cell" />
              </>
            )}
          </div>
        </div>
        <div className="right-scrollable" ref={overlayScroll ? overlayScrollbarRef : rowIndex === 0 ? subCompScroll : null}>
          {getCompsColumns(compData, tabSelected, filters)
            .map((columnGroup) => (
              <div key={columnGroup.columnGroupName} className="row-data-group">
                {columnGroup.columnGroupChildren.map((column) => {
                  if (column.field === 'Short Description' || column.field === 'Long Description') return (
                    <div key={column.field} className="table-cell description-btn" style={{ minWidth: `${column.width}px` }}>
                      {rowIndex !== 0 ? (
                        <Button
                          onMouseDown={(e) => {
                            if (descriptionsToShow[rowIndex] !== (column.field === 'Short Description' ? 'short' : 'long')) {
                              const newDescriptionToShow = [...descriptionsToShow];
                              newDescriptionToShow[rowIndex] = (column.field === 'Short Description' ? 'short' : 'long');
                              setDescriptionsToShow(newDescriptionToShow);
                            } else {
                              const newDescriptionToShow = [...descriptionsToShow];
                              newDescriptionToShow[rowIndex] = '';
                              setDescriptionsToShow(newDescriptionToShow);
                            }
                            if (!e.target.className.includes('Mui-focusVisible')) e.preventDefault();
                          }}
                          onFocus={() => column.field === 'Short Description' ? setShortDescBtnFocused(rowIndex) : setLongDescBtnFocused(rowIndex)}
                          onBlur={() => column.field === 'Short Description' ? setShortDescBtnFocused(-1) : setLongDescBtnFocused(-1)}
                          onMouseOver={() => {
                            if (column.field === 'Short Description' && shortDescBtnHovered !== rowIndex) setShortDescBtnHovered(rowIndex);
                            if (column.field === 'Long Description' && longDescBtnHovered !== rowIndex) setLongDescBtnHovered(rowIndex);
                          }}
                          onMouseLeave={() => column.field === 'Short Description' ? setShortDescBtnHovered(-1) : setLongDescBtnHovered(-1)}
                        >
                          {descriptionsToShow[rowIndex] === (column.field === 'Short Description' ? 'short' : 'long') ? 'Hide' : 'View'}
                        </Button>
                      ) : '-'}
                    </div>
                  );

                  if (column.field === 'Industry') return (
                    <div key={column.field} className="table-cell" style={{ minWidth: `${column.width}px` }}>
                      {compData[rowIndex][column.field] && compData[rowIndex][column.field].length >= 18 ? (
                        <Tooltip title={compData[rowIndex][column.field]} placement="top">
                          <span>{`${compData[rowIndex][column.field].slice(0, 18).trim()}...`}</span>
                        </Tooltip>
                      ) : compData[rowIndex][column.field]}
                    </div>
                  );

                  const extractCompVal = (val) => Array.isArray(val) ? val[0] : val;
                  const compVal = extractCompVal(compData[rowIndex][column.field]);
                  return (
                    <div
                      key={column.field}
                      className="table-cell"
                      style={{ minWidth: `${column.width}px` }}
                    >
                      {column.format ? column.format(compVal) : compVal || '-'}
                    </div>
                  );
                })}
              </div>
            ))}
        </div>
      </div>
      <AnimateHeight
        duration={300}
        height={(descriptionsToShow[rowIndex] === 'long' || descriptionsToShow[rowIndex] === 'short') ? 'auto' : 0}
      >
        <div className={`comp-description${(rowIndex + 1) % 2 === 0 ? ' even' : ' odd'}`}>
          {descriptionsToShow[rowIndex] === 'short' && (
            <div ref={rowIndex !== 0 ? descriptionsRef.current[rowIndex] : null}>
              <p
                className={`description${shortDescBtnFocused === rowIndex ? ' focused' : ''}` +
                  `${shortDescBtnHovered === rowIndex ? ' hovered' : ''}`}
              >
                {compData[rowIndex]['Short Description']}
              </p>
            </div>
          )}
          {descriptionsToShow[rowIndex] === 'long' && (
            <div ref={rowIndex !== 0 ? descriptionsRef.current[rowIndex] : null}>
              <p
                className={`description${longDescBtnFocused === rowIndex ? ' focused' : ''}` +
                  `${longDescBtnHovered === rowIndex ? ' hovered' : ''}`}
              >
                {compData[rowIndex]['Long Description']}
              </p>
            </div>
          )}
          <div className="comp-description-background" />
        </div>
      </AnimateHeight>
    </div>
  );
}

CompTableRow.propTypes = {
  compData: PropTypes.array.isRequired,
  tabSelected: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setShowConfirmationModal: PropTypes.func,
  subCompScroll: PropTypes.object,
  reSummarize: PropTypes.func,
  descriptionsToShow: PropTypes.array,
  setDescriptionsToShow: PropTypes.func,
  descriptionsRef: PropTypes.object,
  checkboxesRef: PropTypes.object,
  rowIndex: PropTypes.number.isRequired,
  overlayScrollbarRef: PropTypes.func,
  overlayScroll: PropTypes.bool,
};

CompTableRow.defaultProps = {
  subCompScroll: {},
  descriptionsRef: {},
  checkboxesRef: {},
  descriptionsToShow: [],
  setDescriptionsToShow: () => { },
  setShowConfirmationModal: () => { },
  reSummarize: () => { },
  overlayScrollbarRef: () => { },
  overlayScroll: false,
};
