import React from 'react';
import PropTypes from 'prop-types';

import Holdings from './exhibits/Exhibit1_Holdings';
import EquityValue from './exhibits/Exhibit2_EquityValue';
import CapTable from './exhibits/Exhibit3_CapTable';
import Breakpoints from './exhibits/Exhibit4_Breakpoints';
import OPMAllocation from './exhibits/Exhibit5a_OPM';
import GPCAllocation from './exhibits/Exhibit5b_GPCAllocation';
import FairValue from './exhibits/Exhibit6_FairValue';
import Volatility from './exhibits/Exhibit8_Volatility';
import PubCoDescriptions from './exhibits/Exhibit9_PubCoDescriptions';
import PubCoMethod from './exhibits/Exhibit10_PubCoMethod';
import PubCoGrowth from './exhibits/Exhibit11_PubCoGrowth';
// import GuidelineMethod from './tables/Exhibit12_GuidelineMethod';
// import GrowthMargins from './tables/Exhibit13_GrowthMargins';
// import GuidelineDescriptions from './tables/Exhibit14_GuidelineDescriptions';
import GeneralNotes from './exhibits/Exhibit15_GeneralNotes';
import ValSumWeighting from './exhibits/Exhibit16a_Weighting';
import ValSumBacksolve from './exhibits/Exhibit16b_Backsolve';
// import ValSumGPC from './tables/Exhibit16c_GPC';
import AllocationMethodSummary from './exhibits/Exhibit17_OPMAllocSummary';
import BacksolveMethod from './exhibits/Exhibit5c_BacksolveMethod';
import TrnDateVolatility from './exhibits/Exhibit8b_TrnDateVolatility';
import ValueComparison from './exhibits/ValueComparison';
import MarketEquityAdjustment from './exhibits/MarketEquityAdjustment';

export default function Exhibits({
  reportData,
  totalPageCount,
  sortedBreakpoints,
  sortedOPMBreakpoints,
  sortedBacksolveBreakpoints,
  sortedPubCoBreakpoints,
  sortedPubCoDescriptions,
  hasValueCompare,
  hasBacksolve,
  hasPubCo,
  exhibitNumber,
}) {
  return (
    <>
      <Holdings reportData={reportData} exhibitNumber={exhibitNumber.fundHolding} />
      <EquityValue reportData={reportData} exhibitNumber={exhibitNumber.equityValue} />
      <CapTable reportData={reportData} exhibitNumber={exhibitNumber.capTable} />
      {sortedBreakpoints.map((breakpoints) => (
        <Breakpoints
          key={breakpoints}
          reportData={reportData}
          sortedBreakpoints={breakpoints}
          totalPageCount={totalPageCount.capTable}
          exhibitNumber={exhibitNumber.breakpoints}
        />
      ))}
      {sortedOPMBreakpoints.map((breakpoints) => (
        <OPMAllocation
          key={breakpoints}
          reportData={reportData}
          sortedBreakpoints={breakpoints}
          totalPageCount={totalPageCount.breakpoints}
          exhibitNumber={exhibitNumber.opmBacksolve}
        />
      ))}
      {sortedBacksolveBreakpoints.map((breakpoints) => (
        <BacksolveMethod
          key={breakpoints}
          reportData={reportData}
          sortedBreakpoints={breakpoints}
          totalPageCount={totalPageCount.opmBacksolve}
          exhibitNumber={exhibitNumber.trnDateBacksolveMethod}
        />
      ))}
      {hasPubCo && (
        sortedPubCoBreakpoints.map((breakpoints) => (
          <GPCAllocation
            key={breakpoints}
            reportData={reportData}
            sortedBreakpoints={breakpoints}
            totalPageCount={totalPageCount.opmPubCo}
            exhibitNumber={exhibitNumber.gpcOPMAllocation}
          />
        )))}
      <FairValue
        reportData={reportData}
        totalPageCount={totalPageCount.fairValue}
        exhibitNumber={exhibitNumber.fairValue}
      />
      <Volatility
        reportData={reportData}
        totalPageCount={totalPageCount.valVolatility}
        exhibitNumber={exhibitNumber.valVolatility}
      />
      <TrnDateVolatility
        reportData={reportData}
        totalPageCount={totalPageCount.tranVolatility}
        exhibitNumber={exhibitNumber.tranVolatility}
      />
      <MarketEquityAdjustment
        reportData={reportData}
        totalPageCount={totalPageCount.marketAdjustment}
        hasPubCo={hasPubCo}
        exhibitNumber={exhibitNumber.marketAdjustment}
      />
      {hasPubCo && (
        <>
          <PubCoDescriptions
            reportData={reportData}
            sortedPubCoDescriptions={sortedPubCoDescriptions}
            totalPageCount={totalPageCount.pubCoDescriptions}
            exhibitNumber={exhibitNumber.pubCoDescriptions}
          />
          <PubCoMethod
            reportData={reportData}
            totalPageCount={totalPageCount.pubCoMethod}
            exhibitNumber={exhibitNumber.pubCoMethod}
          />
          <PubCoGrowth
            reportData={reportData}
            totalPageCount={totalPageCount.pubCoGrowthMargins}
            exhibitNumber={exhibitNumber.pubCoGrowthMargins}
          />
        </>
      )}
      {/* <GuidelineMethod reportData={reportData} /> */}
      {/* <GrowthMargins reportData={reportData} /> */}
      {/* <GuidelineDescriptions reportData={reportData} /> */}
      <GeneralNotes
        reportData={reportData}
        totalPageCount={totalPageCount.generalAppraisersNotes}
        exhibitNumber={exhibitNumber.generalAppraisersNotes}
      />
      <ValSumWeighting
        reportData={reportData}
        totalPageCount={totalPageCount.valSumWeighting}
        exhibitNumber={exhibitNumber.valSumWeighting}
      />
      {hasBacksolve && (
        <ValSumBacksolve
          reportData={reportData}
          totalPageCount={totalPageCount.valSumBacksolve}
          exhibitNumber={exhibitNumber.valSumBacksolve}
        />
      )}
      {/* <ValSumGPC reportData={reportData} totalPageCount={totalPageCount.pubCoDescriptions} /> */}
      <AllocationMethodSummary
        reportData={reportData}
        totalPageCount={totalPageCount.allocationMethodSummary}
        hasPubCo={hasPubCo}
        exhibitNumber={exhibitNumber.allocationMethodSummary}
      />
      {hasValueCompare && (
        <ValueComparison
          reportData={reportData}
          totalPageCount={totalPageCount.valueComparison}
          hasPubCo={hasPubCo}
          exhibitNumber={exhibitNumber.valueComparison}
        />
      )}
    </>
  );
}

Exhibits.propTypes = {
  reportData: PropTypes.object.isRequired,
  sortedBreakpoints: PropTypes.array.isRequired,
  sortedOPMBreakpoints: PropTypes.array.isRequired,
  sortedPubCoBreakpoints: PropTypes.array.isRequired,
  sortedBacksolveBreakpoints: PropTypes.array.isRequired,
  sortedPubCoDescriptions: PropTypes.array.isRequired,
  totalPageCount: PropTypes.object.isRequired,
  hasValueCompare: PropTypes.bool.isRequired,
  hasPubCo: PropTypes.bool.isRequired,
  hasBacksolve: PropTypes.bool.isRequired,
  exhibitNumber: PropTypes.object.isRequired,
};
