import React from 'react';

import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import './RefreshCompsModal.scss';

export default function RefreshCompsModal({
  showRefreshCompsModal,
  setShowRefreshCompsModal,
  runComps,
}) {
  return (
    <Dialog open={showRefreshCompsModal} className="RefreshCompsModal">
      <div className="copy-header-wrapper">
        <UpdateIcon className="update-icon" />
        <div className="header-copy">
          <h2>Update company list</h2>
          <p>
            &apos;Update company list&apos; will update &apos;Company list&apos; table with current data from CapIQ.
            <br />
            The process can take several minutes, but you can continue to work while it updates.
          </p>
        </div>
      </div>
      <div className="btn-wrapper">
        <Button
          type="button"
          className="btn close-btn"
          onClick={() => setShowRefreshCompsModal(false)}
        >
          <CloseIcon />
          Cancel
        </Button>
        <Button
          type="button"
          className="btn refresh-btn"
          onClick={() => {
            runComps({ refresh: true });
            setShowRefreshCompsModal(false);
          }}
        >
          <RefreshIcon />
          Update company list
        </Button>
      </div>
    </Dialog>
  );
}

RefreshCompsModal.propTypes = {
  showRefreshCompsModal: PropTypes.bool.isRequired,
  setShowRefreshCompsModal: PropTypes.func.isRequired,
  runComps: PropTypes.func.isRequired,
};
